<script setup>
import { gsap } from 'gsap'
import { onMounted, watch, ref, onUnmounted } from 'vue'
import linesBeige from '@/assets/images/parallax_imgs/linesBeige.svg'
import linesCanario from '@/assets/images/parallax_imgs/linesCanario.svg'
import linesBYellow from '@/assets/images/parallax_imgs/linesBYellow.svg'
import linesRed from '@/assets/images/parallax_imgs/linesRed.svg'

import ImgGif from '@/components/UserPremium/ImgGif.vue'
import total_streams from '@/assets/images/servicios_empresariales/total_streams.png'
import top_channels from '@/assets/images/servicios_empresariales/top_channels.png'
import top_countries from '@/assets/images/servicios_empresariales/top_countries.png'
import top_movers from '@/assets/images/servicios_empresariales/top_movers.png'
import distribution_w from '@/assets/images/servicios_empresariales/distribution_w.svg'
import clip_dollar_w from '@/assets/images/clip_dollar_w.svg'
import data_w from '@/assets/images/servicios_empresariales/data_w.svg'
import gif05 from '@/assets/images/gifs/gif05.gif'
import AnimatedButton from '@/components/Home/AnimatedButton.vue'
import CardColor from '@/components/UserPremium/CardColor.vue'

/* PARRALAX */
let parallaxContainer = null
let layers = null

onMounted(() => {
  parallaxContainer = document.querySelector('.parallax-container')
  layers = document.querySelectorAll('.layer')

  gsap.set(layers, { x: 0, y: 0 }) // set initial position
  gsap.set(layers[2], { y: 1100 })
  gsap.set(layers[3], { y: 1500 })
  gsap.set(layers[4], { y: 2000 })
  /*  gsap.set(layers[5], { y: 2000 }) */

  parallaxContainer.addEventListener('scroll', () => {
    const scrollPosition = parallaxContainer.scrollTop
    const speed = 0.8

    if (scrollPosition > 1090) {
      gsap.to(layers[0], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[0], {
        x: -scrollPosition * (speed * 1),
        y: scrollPosition * (speed * 0.9),
        ease: 'none',
        duration: 0.7,
      })
    }

    if (scrollPosition > 1000) {
      gsap.to(layers[1], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[1], {
        y: scrollPosition * (speed * 0.6),
        ease: 'none',
        duration: 0.6,
      })
    }

    if (scrollPosition > 2900) {
      gsap.to(layers[2], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[2], {
        y: scrollPosition * (speed * 0.8),
        ease: 'none',
        duration: 0.8,
      })
    }

    if (scrollPosition > 3000) {
      gsap.to(layers[3], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[3], {
        x: -500,
        y: scrollPosition * (speed * 1.2),
        ease: 'none',
        duration: 1,
      })
    }

    /* gsap.to(layers[4], {
      y: scrollPosition * (speed * 0.6),
      ease: 'none',
      duration: 1,
    }) */
    gsap.to(layers[4], {
      x: -scrollPosition * (speed * 0.4),
      ease: 'none',
      duration: 0.9,
    })
  })
})

//DSPLAZAMIENTO AUTOMATICO
onMounted(() => {
  const sections = document.querySelectorAll('section')
  sections.forEach((section) => {
    section.addEventListener('click', () => {
      const nextSection = section.nextElementSibling
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' })
      }
    })
  })
})
//FONDO GRADIENTE
// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const elements = document.querySelectorAll('.bgTransition')

  // Definir los gradientes
  const gradient1 = 'linear-gradient(114deg, #F2A825 -1.23%, #B33B3D 84.52%)'
  const gradient2 = 'linear-gradient(73deg, #F2A825 -2.48%, #B33B3D 84.96%)'
  const gradient3 =
    'linear-gradient(144deg, rgba(179, 59, 61, 0.8) 0%, rgba(242, 168, 37, 1) 100%)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
    .to(elements, { background: gradient2, duration: 3, ease: 'none' })
    .to(elements, { background: gradient3, duration: 3, ease: 'none' })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

// eslint-disable-next-line
const urlLogin = ref('https://panel.themusicdistribution.com/')
// eslint-disable-next-line
const urlSingUp = ref('https://panel.themusicdistribution.com/es/sign-up')
const currentUrl = window.location.pathname
if (currentUrl.indexOf('tmd-access') > -1) {
  urlLogin.value = 'https://distribucion.themusicdistribution.com/'
  urlSingUp.value =
    'https://distribucion.themusicdistribution.com/users/register'
}

const goToPlattform = (type) => {
  if (type === 2) {
    window.location.href = urlLogin.value
  } else {
    window.location.href = urlSingUp.value
  }
}
</script>
<template>
  <div>
    <div class="data-analytics">
      <div class="parallax-container">
        <section id="section1" class="section bgTransition">
          <div class="layer-1">
            <img :src="linesBeige" alt="Layer 1" class="layer-image1 layer" />
            <img :src="linesCanario" alt="Layer 2" class="layer-image2 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  Visualiza<br />
                  tus datos
                </h1>
                <h4>
                  The Music Distribution le permite analizar fácilmente todos
                  sus datos de streaming y tendencias de ingresos con mayor
                  claridad, eficacia y escala.
                </h4>
                <div class="form-group">
                  <button type="button" @click="goToPlattform(1)" class="submit-button" style="text-wrap: nowrap">
                    OBTENLO AHORA
                  </button>
                </div>
              </div>
              <div class="second-container">
                <ImgGif></ImgGif>
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container">
              <div class="box">
                <div class="box-div-first">
                  <span class="text"> Visualice</span>
                  <span class="sub-text">Transmisión de datos</span>
                </div>
                <div class="box-div-second">
                  <span class="text">Comprender</span>
                  <span class="sub-text">Métricas empresariales</span>
                </div>
                <div class="box-div-third">
                  <span class="text">Aumentar</span>
                  <span class="sub-text">Toma de decisiones</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECOND SECTION -->
        <section id="section2" class="section bgBlack">
          <div class="layer-2">
            <img :src="linesBYellow" alt="Layer 3" class="layer-image3 layer" />
            <img :src="linesRed" alt="Layer 4" class="layer-image4 layer" />
          </div>
          <div class="order-third">
            <div class="container">
              <div>
                <div class="div-label-01">
                  <p>Visualice</p>
                </div>
              </div>
              <h2>Visualice todos sus datos de streaming</h2>
              <p>
                Sólo tiene que conectar sus fuentes de datos o cargar informes
                DSP y empezar a visualizar sus datos de flujo.
              </p>

              <AnimatedButton></AnimatedButton>
            </div>
            <div class="d-flex w-100 justify-content-center">
              <div class="img-cont">
                <img :src="total_streams" alt="logos" class="image-logos" />
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 3 -->
        <section id="section3" class="section bgBlack">
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-02">
                  <p>Comprender</p>
                </div>
              </div>
              <h2>Entender su negocio</h2>
              <p>
                Obtenga la imagen completa de sus ingresos reportados,
                tendencias diarias y datos de listas de reproducción en The
                Music Distribution
              </p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="d-flex w-100 justify-content-center">
              <div class="img-cont">
                <div class="container-img">
                  <img :src="top_channels" alt="songs" class="image-songs" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 4 -->
        <section id="section4" class="section bgBlack">
          <div class="layer-2">
            <!-- <img :src="linesBYellow" alt="Layer 3" class="layer-image5 layer" /> -->
            <img :src="linesRed" alt="Layer 4" class="layer-image6 layer" />
          </div>
          <div class="order-third">
            <div class="container">
              <div>
                <div class="div-label-01">
                  <p>Informar</p>
                </div>
              </div>
              <h2>Tome mejores decisiones de marketing</h2>
              <p>
                Utilice los datos para tomar las decisiones correctas en los
                momentos adecuados con una única interfaz. Analice sus datos
                consolidados y vea el impacto inmediato de sus esfuerzos de
                marketing.
              </p>

              <AnimatedButton></AnimatedButton>
            </div>
            <div class="d-flex w-100 justify-content-center">
              <div class="img-cont">
                <img :src="top_countries" alt="logos" class="image-logos" />
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 5 -->
        <section id="section5" class="section overlay-section">
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-03">
                  <p>Operaciones por lotes</p>
                </div>
              </div>
              <h2>Descubra oportunidades ocultas</h2>
              <p>
                Convierta su negocio en una empresa basada en datos de alto
                rendimiento con el poder de los datos y los análisis avanzados.
              </p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="d-flex justify-content-center w-100 overflow-hidden">
              <div class="img-cont-02">
                <div class="container-img">
                  <img :src="top_movers" alt="songs" class="image-songs" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        id="section6"
        class="bgTransition overlay-section2 z-2"
        style="height: auto !important; overflow-x: hidden"
      >
        <div style="height: 100%; width: 100%">
          <div class="layer-1" style="width: 100%; height: 100%">
            <img :src="linesBeige" alt="Layer 1" class="img-last-01 layer" />
            <img :src="linesCanario" alt="Layer 2" class="img-last-02 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  Obtener The music <br />
                  distribution
                </h1>
                <h4>Para tomar decisiones de marketing más inteligentes.</h4>
                <div class="form-group">
                  <button type="button" @click="goToPlattform(1)" class="submit-button">OBTENLO AHORA</button>
                </div>
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container-last" style="min-height: 150px">
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="distribution_w"
                  title="Distribución y entrega"
                  text="Monetizar en todas partes"
                  background="linear-gradient(138deg, #7D1426 -37.97%, #D02140 108.31%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="clip_dollar_w"
                  title="Informes de ingresos"
                  text="Simplificar la elaboración"
                  background="linear-gradient(92deg, #DA9721 -0.12%, #F2A825 100.29%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="data_w"
                  title="Almacenes de datos"
                  text="Almacenes de datos totalmente gestionados"
                  background=" linear-gradient(92deg, #2198D0 -0.12%, #1E89BB 100.29%)"
                ></CardColor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.overlay-section2 {
  position: relative;
}

.overlay-section {
  position: relative;
}

.overlay-section::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 293px; /* Ajusta la altura del resplandor */
  background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #c77729 100%);
  pointer-events: none;
  opacity: 0.56;
  z-index: 3 !important; /* Asegura que el resplandor esté sobre el contenido */
}

.bgBlack {
  width: 100%;
  height: 100vh;
}
.bgTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    286deg,
    rgba(242, 168, 37, 0.8) 0%,
    rgba(189, 76, 57, 0.8) 50%,
    rgba(179, 59, 61, 0.8) 100%
  );
}
.central-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 100px;
  position: relative;
  z-index: 1;
  height: 100vh;
  width: 100%;
}
@media (max-width: 768px) {
  .central-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    height: 100vh;
    width: 100%;
  }
}

.parallax-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.layer {
  position: absolute;
  transform: translateZ(-1px);
}

.layer-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}
.layer-image1 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: -139px;
  bottom: -54px;
}

.layer-image2 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 140px;
}
.layer-image3 {
  width: 618px;
  height: 618px;
  left: -100px;
  bottom: -60%;
  opacity: 0.4;
}
.layer-image4 {
  width: 340px;
  height: 485px;
  right: -120px;
  bottom: -150px;
  opacity: 0.3;
}
.layer-image5 {
  opacity: 0.3;
  width: 618px;
  height: 618px;
  left: 0%;
  top: 180%;
  z-index: 2;
}
.layer-image6 {
  opacity: 0.2;
  width: 340px;
  height: 485px;
  right: -10%;
  top: 170%;
  z-index: 2;
}

@media (max-width: 768px) {
  .layer-image1 {
    width: 480px;
    height: 480px;
    right: -139px;
    bottom: -54px;
  }

  .layer-image2 {
    width: 250px;
    height: 395px;
    right: -150px;
    bottom: 140px;
  }
  .layer-image3 {
    width: 480px;
    height: 480px;
    left: -100px;
    bottom: -45%;
  }
  .layer-image4 {
    width: 250px;
    height: 395px;
    right: -50%;
    bottom: -150px;
  }
  .layer-image5 {
    width: 480px;
    height: 480px;
    left: -30%;
    top: 200%;
  }
  .layer-image6 {
    width: 250px;
    height: 395px;
    right: -80%;
    top: 170%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .layer-image4 {
    right: -40%;
    bottom: -150px;
  }
  .layer-image6 {
    width: 340px;
    height: 485px;
    right: -40%;
    top: 150%;
  }
}
@media (min-width: 1600px) {
  .layer-image1 {
    right: 0%;
    bottom: -10%;
  }
  .layer-image4 {
    right: -120px;
    bottom: -20%;
  }
  .layer-image5 {
    right: 50%;
    top: 250%;
    z-index: 1;
  }
  .layer-image6 {
    width: 340px;
    height: 485px;
    right: 12%;
    top: 200%;
    z-index: 2;
  }
}
@media (max-width: 480px) {
  /* Estilos para dispositivos móviles pequeños */
  .layer-image1 {
    width: 300px;
    height: 300px;
    opacity: 0.2;
    right: -50px;
    bottom: -20px;
  }
  .layer-image2 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -70px;
    bottom: 100px;
  }
  .layer-image3 {
    width: 300px;
    height: 300px;
    left: -50px;
    bottom: -20%;
    opacity: 0.5;
  }
  .layer-image4 {
    width: 180px;
    height: 280px;
    right: -140%;
    bottom: -50px;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    width: 180px;
    height: 280px;
    right: -260%;
    bottom: 40%;
  }
  .layer-image6 {
    width: 180px;
    height: 280px;
    right: -200%;
    top: 70%;
  }
}
.img-last-01 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: 5%;
  bottom: 20%;
  overflow: hidden;
}
.img-last-02 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 30%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .img-last-01 {
    width: 300px;
    height: 300px;
    opacity: 0.2;
    right: -90px;
    bottom: 20%;
    overflow: hidden !important;
  }

  .img-last-02 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -100px;
    bottom: 30%;
    overflow: hidden !important;
  }
}
</style>
