<script setup>
import { onMounted, watch, ref, onUnmounted } from 'vue'
import { gsap } from 'gsap'
import linesBeige from '@/assets/images/parallax_imgs/linesBeige.svg'
import linesCanario from '@/assets/images/parallax_imgs/linesCanario.svg'
import linesBYellow from '@/assets/images/parallax_imgs/linesBYellow.svg'
import linesRed from '@/assets/images/parallax_imgs/linesRed.svg'
import frame_130 from '@/assets/images/servicios_empresariales/frame_130.png'
import frame_131 from '@/assets/images/servicios_empresariales/frame_131.png'
import monetizacion from '@/assets/images/servicios_empresariales/monetizacion.png'
import controlc from '@/assets/images/servicios_empresariales/controlc.png'
import distribution_w from '@/assets/images/servicios_empresariales/distribution_w.svg'
import clip_dollar_w from '@/assets/images/clip_dollar_w.svg'
import data_w from '@/assets/images/servicios_empresariales/data_w.svg'
import gif02 from '@/assets/images/gifs/gif02.gif'
import AnimatedButton from '@/components/Home/AnimatedButton.vue'
import CardColor from '@/components/UserPremium/CardColor.vue'

/* PARRALAX */
let parallaxContainer = null
let layers = null

onMounted(() => {
  parallaxContainer = document.querySelector('.parallax-container')
  layers = document.querySelectorAll('.layer')

  gsap.set(layers, { x: 0, y: 0 }) // set initial position
  gsap.set(layers[2], { y: 1500 })
  gsap.set(layers[3], { y: 1500 })
  gsap.set(layers[4], { y: 3200 })

  parallaxContainer.addEventListener('scroll', () => {
    const scrollPosition = parallaxContainer.scrollTop
    const speed = 0.8

    if (scrollPosition > 980) {
      gsap.to(layers[0], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[0], {
        x: -scrollPosition * speed,
        y: scrollPosition * (speed * 1.3),
        ease: 'none',
        duration: 0.7,
      })
    }

    if (scrollPosition > 900) {
      gsap.to(layers[1], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[1], {
        y: scrollPosition * (speed * 0.6),
        ease: 'none',
        duration: 0.6,
      })
    }

    if (scrollPosition > 2900) {
      gsap.to(layers[2], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[2], {
        y: scrollPosition * (speed * 0.7),
        ease: 'none',
        duration: 0.8,
      })
    }

    if (scrollPosition > 2700) {
      gsap.to(layers[3], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[3], {
        y: scrollPosition * (speed * 1.1),
        ease: 'none',
        duration: 0.8,
      })
    }

    gsap.to(layers[4], {
      x: -scrollPosition * (speed * 0.5),
      ease: 'none',
      duration: 0.8,
    })
  })
})

//DSPLAZAMIENTO AUTOMATICO
onMounted(() => {
  const sections = document.querySelectorAll('section')
  sections.forEach((section) => {
    section.addEventListener('click', () => {
      const nextSection = section.nextElementSibling
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' })
      }
    })
  })
})
//FONDO GRADIENTE
// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const elements = document.querySelectorAll('.bgTransition')

  // Definir los gradientes
  const gradient1 = 'linear-gradient(114deg, #F2A825 -1.23%, #B33B3D 84.52%)'
  const gradient2 = 'linear-gradient(73deg, #F2A825 -2.48%, #B33B3D 84.96%)'
  const gradient3 =
    'linear-gradient(144deg, rgba(179, 59, 61, 0.5) 0%, rgba(242, 168, 37, 1) 100%)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
    .to(elements, { background: gradient2, duration: 3, ease: 'none' })
    .to(elements, { background: gradient3, duration: 3, ease: 'none' })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

//Cambiar la imagen dependiendo la pantalla
// Estado reactivo para el ancho de pantalla
const anchoPantalla = ref(window.innerWidth)

const actualizarAnchoPantalla = () => {
  anchoPantalla.value = window.innerWidth
  console.log('Ancho de pantalla actualizado:', anchoPantalla.value)
}

onMounted(() => {
  window.addEventListener('resize', actualizarAnchoPantalla)
  console.log('Componente montado, ancho inicial:', anchoPantalla.value)
})

onUnmounted(() => {
  window.removeEventListener('resize', actualizarAnchoPantalla)
})

const imagen = ref(anchoPantalla.value <= 1024 ? frame_131 : frame_130)

watch(anchoPantalla, (nuevoValor) => {
  console.log('Nuevo valor de ancho de pantalla:', nuevoValor)
  if (nuevoValor <= 1024) {
    imagen.value = frame_131
  } else {
    imagen.value = frame_130
  }
  console.log('Imagen actual:', imagen.value)
})
// eslint-disable-next-line
const urlLogin = ref('https://panel.themusicdistribution.com/')
// eslint-disable-next-line
const urlSingUp = ref('https://panel.themusicdistribution.com/es/sign-up')
const currentUrl = window.location.pathname
if (currentUrl.indexOf('tmd-access') > -1) {
  urlLogin.value = 'https://distribucion.themusicdistribution.com/'
  urlSingUp.value =
    'https://distribucion.themusicdistribution.com/users/register'
}

const goToPlattform = (type) => {
  if (type === 2) {
    window.location.href = urlLogin.value
  } else {
    window.location.href = urlSingUp.value
  }
}
</script>
<template>
  <div>
    <div class="distribution-delivery">
      <div class="parallax-container">
        <section id="section1" class="section bgTransition">
          <div class="layer-1">
            <img :src="linesBeige" alt="Layer 1" class="layer-image1 layer" />
            <img :src="linesCanario" alt="Layer 2" class="layer-image2 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  potencia tu <br />
                  distribución
                </h1>
                <h4>
                  Ofrecemos una plataforma para gestionar tu catálogo musical,
                  inteligencia empresarial, informes financieros y distribución
                  de pagos de derechos.
                </h4>
                <div class="form-group">
                  <button
                    type="button"
                    @click="goToPlattform(1)"
                    class="submit-button"
                    style="text-wrap: nowrap"
                  >
                    OBTENLO AHORA
                  </button>
                </div>
              </div>
              <div class="second-container">
                <img
                  :src="gif02"
                  alt="logo-gif"
                  style="width: 100%; height: 100%; object-fit: fill"
                />
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container">
              <div class="box">
                <div class="box-div-first">
                  <span class="text"> Escalable</span>
                  <span class="sub-text">Cadena de suministro</span>
                </div>
                <div class="box-div-second">
                  <span class="text">Flexible</span>
                  <span class="sub-text">Modelos de negocio</span>
                </div>
                <div class="box-div-third">
                  <span class="text"> Global</span>
                  <span class="sub-text">Alcance comercial</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECOND SECTION -->
        <section id="section2" class="section bgBlack">
          <div class="layer-2">
            <img :src="linesBYellow" alt="Layer 3" class="layer-image3 layer" />
            <img :src="linesRed" alt="Layer 4" class="layer-image4 layer" />
          </div>
          <div class="order-third">
            <div class="container">
              <div>
                <div class="div-label-01">
                  <p>Distribución</p>
                </div>
              </div>
              <h2>Acceso a los mercados mundiales</h2>
              <p>
                Distribuye a las principales plataformas y servicios de
                streaming, como Apple, Spotify, YouTube, Deezer, Amazon, TikTok,
                Facebook, Instagram, UMA, Audiomack y muchos más.
              </p>

              <AnimatedButton></AnimatedButton>
            </div>
            <div class="d-flex w-100 justify-content-center">
              <div class="img-cont">
                <img :src="imagen" alt="logos" class="image-logos" />
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 3 -->
        <section id="section3" class="section bgBlack">
          <div class="layer-3">
            <img :src="linesRed" alt="Layer 5" class="layer-image5 layer" />
          </div>
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-02">
                  <p>Monetización</p>
                </div>
              </div>
              <h2>Monetizar en todas las plataformas UGC</h2>
              <p>Monetizar en todas las plataformas UGC</p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="d-flex w-100 justify-content-center">
              <div class="img-cont">
                <div class="container-img">
                  <img :src="monetizacion" alt="songs" class="image-songs" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 4 -->
        <section id="section4" class="section overlay-section">
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-03">
                  <p>Operaciones de control de calidad</p>
                </div>
              </div>
              <h2>Agilice el control de calidad</h2>
              <p>
                Inspeccione y valide las publicaciones para cumplir las guías de
                estilo de DSP. Entregue metadatos perfectos en todo el mercado
                global.
              </p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="d-flex justify-content-center w-100">
              <div class="img-cont-02">
                <div class="container-img">
                  <img :src="controlc" alt="songs" class="image-songs" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        id="section5"
        class="bgTransition overlay-section2 z-2"
        style="height: auto !important; overflow-x: hidden"
      >
        <div style="height: 100%; width: 100%">
          <div
            class="layer-1"
            style="width: 100%; height: 100%; overflow: hidden"
          >
            <img :src="linesBeige" alt="Layer 1" class="img-last-01 layer" />
            <img :src="linesCanario" alt="Layer 2" class="img-last-02 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  Obtener The music <br />
                  distribution
                </h1>
                <h4>Para potenciar su entrega y distribución.</h4>
                <div class="form-group">
                  <button
                    type="button"
                    @click="goToPlattform(1)"
                    class="submit-button"
                  >
                    OBTENLO AHORA
                  </button>
                </div>
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container-last" style="min-height: 150px">
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="distribution_w"
                  title="Distribución y entrega"
                  url="/tmd-pro-access/servicios-empresariales/distribucion-entrega"
                  text="Monetizar en todas partes"
                  background="linear-gradient(138deg, #7D1426 -37.97%, #D02140 108.31%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="clip_dollar_w"
                  title="Informes de ingresos"
                  url="/tmd-pro-access/servicios-empresariales/informes-ingresos"
                  text="Simplificar la elaboración"
                  background="linear-gradient(92deg, #DA9721 -0.12%, #F2A825 100.29%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="data_w"
                  title="Almacenes de datos"
                  url="/tmd-pro-access/servicios-datos/almacenamiento-datos"
                  text="Almacenes de datos totalmente gestionados"
                  background=" linear-gradient(92deg, #2198D0 -0.12%, #1E89BB 100.29%)"
                ></CardColor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.overlay-section2 {
  position: relative;
}

.overlay-section {
  position: relative;
}

.overlay-section::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 293px; /* Ajusta la altura del resplandor */
  background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #c77729 100%);
  pointer-events: none;
  opacity: 0.56;
  z-index: 3 !important; /* Asegura que el resplandor esté sobre el contenido */
}

.bgBlack {
  width: 100%;
  height: 100vh;
}
.bgTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    144deg,
    rgba(179, 59, 61, 1) 0%,
    rgba(242, 168, 37, 1) 100%
  );
}
.central-content {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 6rem;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .central-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    height: 100vh;
    width: 100%;
  }
}

.parallax-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
@media (max-width: 768px) {
  .parallax-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}
.layer {
  position: absolute;
  transform: translateZ(-1px);
}

.layer-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}
.layer-image1 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: -139px;
  bottom: -54px;
}

.layer-image2 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 140px;
}
.layer-image3 {
  width: 618px;
  height: 618px;
  left: -100px;
  bottom: -45%;
  opacity: 0.3;
}
.layer-image4 {
  width: 340px;
  height: 485px;
  right: -120px;
  bottom: 5%;
  opacity: 0.5;
}
.layer-image5 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: 8%;
  top: -100%;
  z-index: 2;
}

@media (max-width: 768px) {
  .layer-image1 {
    width: 518px;
    height: 518px;
    opacity: 0.2;
    right: -139px;
    bottom: -54px;
  }

  .layer-image2 {
    opacity: 0.3;
    width: 240px;
    height: 385px;
    right: -150px;
    bottom: 140px;
  }
  .layer-image3 {
    width: 508px;
    height: 508px;
    left: -100px;
    bottom: -45%;
    opacity: 0.5;
  }
  .layer-image4 {
    width: 240px;
    height: 385px;
    right: -120px;
    bottom: -100px;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    width: 240px;
    height: 385px;
    right: -85%;
    top: -280px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .layer-image5 {
    right: -100%;
    top: 30%;
  }
}
@media (min-width: 1600px) {
  .layer-image1 {
    opacity: 0.2;
    right: 0%;
    bottom: -10%;
  }
  .layer-image4 {
    right: -120px;
    bottom: -20%;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    right: 15%;
    top: -50%;
    z-index: 1;
  }
}
@media (max-width: 480px) {
  /* Estilos para dispositivos móviles pequeños */
  .layer-image1 {
    width: 300px;
    height: 300px;
    opacity: 0.2;
    right: -50px;
    bottom: -20px;
  }
  .layer-image2 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -70px;
    bottom: 100px;
  }
  .layer-image3 {
    width: 300px;
    height: 300px;
    left: -50px;
    bottom: -20%;
    opacity: 0.5;
  }
  .layer-image4 {
    width: 180px;
    height: 280px;
    right: -60px;
    bottom: -50px;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    width: 180px;
    height: 280px;
    right: -260%;
    top: -200px;
  }
}
.img-last-01 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: 5%;
  bottom: 20%;
  overflow: hidden;
}
.img-last-02 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 30%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .img-last-01 {
    width: 318px;
    height: 318px;
    opacity: 0.2;
    right: -139px;
    bottom: 0px;
    overflow: hidden !important;
  }

  .img-last-02 {
    opacity: 0.3;
    width: 240px;
    height: 385px;
    right: -150px;
    bottom: 140px;
    overflow: hidden !important;
  }
}
</style>
