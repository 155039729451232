import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/main.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { TextPlugin } from 'gsap/TextPlugin'

// Registrar el plugin ScrollTrigger
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(TextPlugin)

createApp(App).use(store).use(router).mount('#app')
