<script setup>
import { gsap } from 'gsap'
import { onMounted } from 'vue'
import vinilo from '@/assets/images/vinilo.png'
import logotmd from '@/assets/images/logotmd.png'
import svg_heart from '@/assets/images/svg_heart.png'
import GridCards from '@/components/Home/GridCards.vue'
import reproductor from '@/assets/images/reproductor.png'
import spotify_b from '@/assets/images/spotify_b.svg'
import apple_music_b from '@/assets/images/apple_music_b.svg'
import youtube_b from '@/assets/images/youtube_b.svg'
import deezer_b from '@/assets/images/deezer_b.svg'
import amazon_m_b from '@/assets/images/amazon_m_b.svg'
import tiktok_b from '@/assets/images/tiktok_b.svg'
import PublishingForm from '@/components/Home/PublishingForm.vue'
import AnimatedButton from '@/components/Home/AnimatedButton.vue'
import lines01 from '@/assets/images/parallax_imgs/lines01.svg'
import lines02 from '@/assets/images/parallax_imgs/lines02.svg'
import lines03 from '@/assets/images/parallax_imgs/lines03.svg'
/* import lines04 from '@/assets/images/parallax_imgs/lines04.svg' */
import lines05 from '@/assets/images/parallax_imgs/lines05.svg'
import lines06 from '@/assets/images/parallax_imgs/lines06.svg'
/* import lines07 from '@/assets/images/parallax_imgs/lines07.svg' */
import lines08 from '@/assets/images/parallax_imgs/lines08.svg'
import line09 from '@/assets/images/parallax_imgs/line09.png'
import VideoMask from '@/components/Home/VideoMask.vue'
import gif02 from '@/assets/images/gifs/gif02.gif'
import Amazon from '@/assets/images/basic_user/amazonmusic.svg'
import YouTube from '@/assets/images/basic_user/youtube.svg'
import Spotify from '@/assets/images/home/spotify_home.png'
import Apple from '@/assets/images/home/apple.png'
import Deezer from '@/assets/images/basic_user/Deezer.svg'
import TikTok from '@/assets/images/home/tiktok.svg'
import GooglePlay from '@/assets/images/basic_user/GooglePlay.svg'

const animateElement = () => {
  // Seleccionar el elemento
  const element = document.querySelectorAll('.title-section-color')

  // Definir los gradientes
  // Definir los gradientes
  const gradient1 =
    'conic-gradient(from 90deg at 50% 50%, #21090f 0%, #394c6b 30deg, #28729d 165deg, #681e32 188deg, #66101f 320deg, #360811 350deg, #100305 358deg)'
  const gradient2 =
    'conic-gradient(from -90deg at 50% 50%, #bb8419 0%, #994621 35deg, #6c1321 165deg, #480b15 188deg, #2d0c0c 310deg, #4e1c13 335deg, #bb8319 358deg)'
  const gradient3 =
    'conic-gradient(from 2deg at 50% 52.13%, #1f82b3 0%, #3f879a 50deg, #e7a118 177.36157536506653deg, #ac5028 240deg, #442224 268.28046798706055deg, #5d2e40 280deg, #247faf 320deg)'
  const gradient4 =
    'from 0deg at 50% 50%, #21090f 0%, #681e32 50deg, #394c6b 80deg, #28729d 98deg, #2180b1 150deg, #94192f 280deg, #360811 350deg, #100305 360deg)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(element, { color: gradient1, duration: 3, ease: 'none' })
    .to(element, { color: gradient2, duration: 3, ease: 'none' })
    .to(element, { color: gradient3, duration: 3, ease: 'none' })
    .to(element, { color: gradient4, duration: 3, ease: 'none' })
}
// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const element = document.querySelector('.bgTransition')

  // Definir los gradientes
  const gradient1 = 'linear-gradient(127deg, #205771 18.11%, #000 88.57%)'
  const gradient2 = 'linear-gradient(72deg, #205771 0%, #000 81.79%)'
  const gradient3 = 'linear-gradient(293deg, #205771 3.42%, #000 89.99%)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(element, { background: gradient2, duration: 3, ease: 'none' })
    .to(element, { background: gradient3, duration: 3, ease: 'none' })
    .to(element, { background: gradient1, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

onMounted(() => {
  animateElement()
})
// Método para animar el fondo con gradientes sixth section
const animateSecondBackground = () => {
  // Seleccionar el elemento
  const element = document.querySelector('.bgSecondTransition')

  // Definir los gradientes
  const gradient1 =
    'linear-gradient(127deg, rgba(72, 2, 19, 0.9) 18.11%, rgba(0, 0, 0, 0.9) 88.57%)'
  const gradient2 =
    'linear-gradient(127deg, rgba(0, 0, 0, 0.9) 18.11%, rgba(7, 48, 67, 0.9) 88.57%)'
  const gradient3 =
    'linear-gradient(109deg, rgba(27, 158, 219, 0.2) 2.72%, rgba(242, 168, 37, 0.2) 97.21%)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(element, { background: gradient1, duration: 2, ease: 'none' })
    .to(element, { background: gradient2, duration: 2, ease: 'none' })
    .to(element, { background: gradient3, duration: 2, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateSecondBackground()
})
//PARALLAX
let parallaxElements = document.querySelectorAll('.parallax')

window.addEventListener('scroll', function () {
  parallaxElements.forEach(function (element) {
    let scrollPosition = window.pageYOffset
    let elementTop = element.getBoundingClientRect().top
    let elementHeight = element.offsetHeight
    let scrollPercent = (scrollPosition - elementTop) / (elementHeight * 0.6)

    element.style.transform =
      'translate3d(0px, ' + scrollPercent * 100 + 'px, 0px)'
  })
})

// Método para animar el desplazamiento suave
const scrollToNextSection = (currentSectionId) => {
  const currentSectionIndex = parseInt(currentSectionId.replace('section', ''))
  const nextSectionIndex = currentSectionIndex + 1
  const nextSectionId = `section${nextSectionIndex}`

  const nextSection = document.getElementById(nextSectionId)

  if (nextSection) {
    const yPos = nextSection.offsetTop
    window.scrollTo({ top: yPos, behavior: 'smooth' })
    // Imprimir los valores de las referencias
    console.log('currentSectionId:', currentSectionId)
    console.log('nextSectionId:', nextSectionId)

    // Actualizar el parallax al hacer scroll
    parallaxElements.forEach(function (element) {
      let scrollPosition = window.pageYOffset
      let elementTop = element.getBoundingClientRect().top
      let elementHeight = element.offsetHeight
      let scrollPercent = (scrollPosition - elementTop) / (elementHeight * 1)

      element.style.transform =
        'translate3d(0px, ' + scrollPercent * 100 + 'px, 0px)'
    })
  }
}

window.onload = function () {
  parallaxElements = document.querySelectorAll('.parallax')
  console.log('PARALAX E:', parallaxElements)
}
</script>
<template>
  <div class="home">
    <section
      id="section0"
      class="bgTransition parallax-section section0"
      @click="scrollToNextSection('section0')"
    >
      <div class="background-container">
        <img
          :src="lines01"
          alt="lines"
          class="parallax-image image1 parallax"
        />
        <img
          :src="lines02"
          alt="lines"
          class="parallax-image image2 parallax"
        />
      </div>
      <div class="order-first z-1">
        <div class="d-flex flex-column gap-5 gap-md-4">
          <p class="title-one title-section-color">
            Distribuye tu música mundialmente en todas las plataformas digitales
          </p>
          <div class="ul-lis">
            <div class="container">
              <div class="img-order">
                <div class="col">
                  <img width="100px" :src="Spotify" alt="Spotify" class="" />
                </div>
                <div class="col">
                  <img
                    width="100px"
                    :src="apple_music_b"
                    alt="Apple Music"
                    class=""
                  />
                </div>
                <div class="col">
                  <img :src="YouTube" alt="YouTube" class="" />
                </div>
                <div class="col">
                  <img :src="Deezer" alt="Deezer" class="" />
                </div>
                <div class="col">
                  <img width="100px" :src="tiktok_b" alt="TikTok" class="" />
                </div>
                <div class="col">
                  <img :src="GooglePlay" alt="GooglePlay" class="" />
                </div>
                <div class="col">
                  <img :src="Amazon" alt="Amazon Music" class="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="third-container third-container3">
          <div class="box container">
            <div class="box-div-first">
              <span class="text mobile-text"> TECH </span>
            </div>
            <div class="box-div-second">
              <span class="text mobile-text">MARKETING</span>
            </div>
            <div class="box-div-third">
              <span class="text mobile-text"> CUSTOM SUPPORT</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="section1"
      class="bgTransition parallax-section"
      @click="scrollToNextSection('section1')"
    >
      <div class="background-container">
        <img
          :src="lines01"
          alt="lines"
          class="parallax-image image1 parallax"
        />
        <img
          :src="lines02"
          alt="lines"
          class="parallax-image image2 parallax"
        />
      </div>
      <div class="order">
        <div class="central-content" style="height: 100vh">
          <div class="first-container">
            <h1>
              Se independiente <br />
              y lleva tu música <br />
              al siguiente nivel
            </h1>
            <h4>
              The Music Distribution es la Plataforma de distribución musical
              independiente para cualquier artista y sello discográfico en
              cualquier etapa de su carrera musical, ofrece servicios de
              distribución de música digital, distribución de video, recolección
              de regalías, tecnología, asistencia personalizada, marketing y
              mas.
            </h4>
            <AnimatedButton></AnimatedButton>
          </div>
          <div class="second-container">
            <!--div class="image-stack">
              <img :src="vinilo" alt="vinilo" class="image vinilo" />
              <img :src="logotmd" alt="logo" class="image logotmd" />
              <img :src="svg_heart" alt="heart" class="image heart" />
            </div-->
            <img :src="gif02" alt="logo-gif" class="img-principal" />
          </div>
        </div>
        <!--div class="ul-lis mt-5 mb-5">
          <div class="container">
            <div class="row text-white">
              <div class="col mb-3">
                <img width="100px" :src="Spotify" alt="Spotify" class="" />
              </div>
              <div class="col mb-3">
                <img width="100px" :src="Apple" alt="Apple Music" class="" />
              </div>
              <div class="col mb-3">
                <img :src="YouTube" alt="YouTube" class="" />
              </div>
              <div class="col mb-3">
                <img :src="Deezer" alt="Deezer" class="" />
              </div>
              <div class="col mb-3">
                <img width="100px" :src="TikTok" alt="TikTok" class="" />
              </div>
              <div class="col mb-3">
                <img :src="GooglePlay" alt="GooglePlay" class="" />
              </div>
              <div class="col mb-3">
                <img :src="Amazon" alt="Amazon Music" class="" />
              </div>
            </div>
          </div>
        </div-->
        <!-- *Aqui cut
        <div class="third-container">
          <div class="box">
            <div class="box-div-first"><span class="text"> TECH </span></div>
            <div class="box-div-second">
              <span class="text">MARKETING</span>
            </div>
            <div class="box-div-third">
              <span class="text"> CUSTOM SUPPORT</span>
            </div>
          </div>
        </div-->
      </div>
    </section>
    <!-- *Second section -->
    <section
      id="section2"
      class="bgBlack parallax-section"
      @click="scrollToNextSection('section2')"
    >
      <div class="background-container">
        <img
          :src="lines01"
          alt="lines"
          class="parallax-image image3 parallax"
        />
        <img
          :src="lines02"
          alt="lines"
          class="parallax-image image4 parallax"
        />
      </div>
      <div class="order-second">
        <GridCards></GridCards>
        <AnimatedButton></AnimatedButton>
      </div>
    </section>
    <!-- *Third section -->
    <section
      id="section3"
      class="bgBlack parallax-section"
      @click="scrollToNextSection('section3')"
    >
      <div class="background-container">
        <img
          :src="lines01"
          alt="lines"
          class="parallax-image image5 parallax"
        />
        <img
          :src="lines03"
          alt="lines"
          class="parallax-image image6 parallax"
        />
      </div>
      <div class="order-third">
        <div class="container">
          <h2>Marketing</h2>
          <p>
            Aplicamos estrategias de marketing conforme a las estadísticas de
            cada creador para poder potencializar su proyecto y llevarlo al next
            level.
          </p>
          <div class="buttons-container">
            <div class="btn">Smartlinks</div>
            <div class="btn">Pre-saves</div>
            <div class="btn">Marketing DSPs</div>
          </div>
          <AnimatedButton></AnimatedButton>
        </div>
        <div class="img-cont">
          <div class="resizer">
            <img
              :src="reproductor"
              alt="reproductor"
              style="width: 100%; height: 100%; object-fit: fill"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- *fourth section -->
    <section id="section4" class="bgBlack parallax-section">
      <div class="background-container">
        <img
          :src="lines06"
          alt="lines"
          class="parallax-image image7 parallax"
        />
        <img
          :src="lines05"
          alt="lines"
          class="parallax-image image8 parallax"
        />
      </div>
      <div class="order-fourth">
        <h3>Acceso a los mercados mundiales</h3>
        <p>
          Distribuye a las plataformas y servicios de streaming, como Spotify,
          Apple, Youtube, Deezer, Amazon, TikTok, Facebook, Instagram, y muchos
          más.
        </p>
        <div class="base">
          <div class="grid-container">
            <img :src="spotify_b" alt="spotify-logo" class="grid-item" />
            <img
              :src="apple_music_b"
              alt="apple-music-logo"
              class="grid-item"
            />
            <img :src="youtube_b" alt="youtube-logo" class="grid-item" />
            <img :src="deezer_b" alt="deezer-logo" class="grid-item" />
            <img :src="amazon_m_b" alt="amazon-music-logo" class="grid-item" />
            <img :src="tiktok_b" alt="tiktock-logo" class="grid-item" />
          </div>
        </div>
      </div>
    </section>
    <!-- *fifth section -->
    <section id="section5" class="bgBlack target-section section-5">
      <div class="sticky-background">
        <img :src="line09" alt="lines" class="background-image1" />
        <img :src="lines08" alt="lines" class="background-image2" />
        <div class="order-fifth">
          <div class="text">
            <h1>TMD <span> PUBLISHING </span></h1>
            <h4>
              Si no tienes una editora, no hay problema. Envía tu solicitud y
              descubre cómo en The Music Distribution lleva tu música al
              siguiente nivel.
            </h4>
          </div>
          <PublishingForm></PublishingForm>
        </div>
      </div>
    </section>
    <!-- *sixth section -->
    <section id="section6" class="target-section bgSecondTransition section-5">
      <div class="sticky-background">
        <img :src="line09" alt="lines" class="background-image1" />
        <img :src="lines08" alt="lines" class="background-image2" />
        <div class="order-sixth">
          <div class="text">
            <h1>OUR COMPANY</h1>
            <h5>
              Con sede en el estado de Texas, US. y presencia en Mexico
              principalmente en el genero Regional Mexicano, nos hemos enfocado
              en brindarles nuestros servicios a la comunidad latina siendo una
              empresa 100% mexicana enfocados en el desarrollo de nuestros
              artistas demostrando un aumento en audiencia y reproducciones
              bastantes considerables desde artistas emergentes convirtiéndose
              en artistas relevantes en su género, asi como artistas relevantes
              convirtiéndose en artistas consolidados.<br /><br />
              <span>
                Nuestra vision: Ser la empresa de distribución musical a la
                vanguardia en tech y marketing musical para brindarles un mejor
                servicio cada vez a nuestros clientes.
              </span>
            </h5>
          </div>
          <div class="container-mask">
            <VideoMask></VideoMask>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped lang="scss">
.img-order {
  display: flex;
  flex-direction: row;
  color: white;
  gap: 2rem;
}
@media (max-width: 1024px) {
  .img-order {
    flex-wrap: wrap;
    gap: 1rem;
    .col {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    img {
      width: 6rem;
      height: auto;
    }
  }
}
@media (max-width: 768px) {
  .section0 {
    height: 100vh !important; /* old browsers */  
    height: 100dvh !important; /* new browsers */
    min-height: calc(100vh - 6rem) !important;
  }
  @supports (height: 100svh) {
    .section0 {
        min-height: calc(100svh - 6rem) !important;
    }
  }
  .img-order {
    flex-wrap: wrap;
    gap: 1rem;
    .col {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    img {
      width: 5rem;
      height: auto;
    }
  }
}
.title-section-color {
  // background: -webkit-linear-gradient(127.23deg, #480213 18.11%, #000000 88.57%);
  //background: conic-gradient(from 180deg at 90% 52.13%, #f0b209de 0deg, rgba(31, 1, 1, 0.918) 177.36deg, rgba(42, 4, 11, 0) 268.28deg, #1F7AA7 360deg);
  background: #fff;
  //background: conic-gradient(from 180deg at 80% 52.13%, #1F7AA7 0deg, rgba(197, 6, 41, 0.936) 177.36deg, rgba(208, 33, 64, 0) 268.28deg, #1F7AA7 360deg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home .third-container {
  display: flex;
  justify-content: space-around;
  background: #000000;
  padding: 0 0px;
  width: 100%;
  z-index: 2;
}

.home h4,
.home .h4 {
  font-size: 18px !important;
}
.img-principal {
  max-width: 550px;
  vertical-align: top !important;
}
.order-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.title-one {
  padding-top: 10%;
  padding-right: 120px;
  padding-left: 120px;
  align-self: normal !important;
  font-family: 'Oswald', sans-serif;
  font-size: 88px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2 !important;
  text-transform: uppercase;
  max-width: 100% !important;
  text-align: center;
}
.order-one {
  font-size: 50px;
}
.container-mask {
  position: absolute;
  right: -23%;
  z-index: 2;
}
@media (max-width: 1024px) {
  .order-first {
    padding-top: 10%;
  }
  .container-mask {
    position: absolute;
    bottom: -20%;
    right: 0%;
    z-index: -1;
    transform: scale(0.7);
  }
  .title-one {
    padding-top: 5%;
    padding-right: 120px;
    padding-left: 120px;
    align-self: normal !important;
    font-family: 'Oswald', sans-serif;
    font-size: 70px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2 !important;
    text-transform: uppercase;
    max-width: 100% !important;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .parallax-section {
    padding-bottom: 0px !important;
  }
  .title-one {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 70px !important;
    align-self: normal !important;
    font-family: 'Oswald', sans-serif;
    font-size: 50px !important;
    font-style: normal;
    font-weight: 600;
    height: 100%;
    line-height: 1.2 !important;
    text-transform: uppercase;
    max-width: 100% !important;
    vertical-align: middle !important;
  }

  #section6 {
    height: 160vh !important;
    padding-bottom: 200px;
  }
  .section-5 {
    height: 140vh !important;
  }
  .section-5 .sticky-background {
    padding-bottom: 200px;
    height: 150vh !important;
  }
  .parallax-section {
    padding-bottom: 200px;
  }
  .container-mask {
    position: absolute;
    bottom: -25%;
    right: 0%;
    z-index: -1;
    transform: scale(0.7);
  }
}
@media (max-width: 400px) {
  .title-one {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
    margin-top: 40px !important;
    margin-bottom: 0px;
    padding-top: 70px !important;
    align-self: normal !important;
    font-family: 'Oswald', sans-serif;
    font-size: 35px !important;
    font-style: normal;
    font-weight: 600;
    height: 100%;
    line-height: 1.2 !important;
    text-transform: uppercase;
    max-width: 100% !important;
    vertical-align: middle !important;
  }
}
.bgSecondTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    127deg,
    rgba(72, 2, 19, 0.9) 18.11%,
    rgba(0, 0, 0, 0.9) 88.57%
  );
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; // Para que el pseudo-elemento esté detrás del contenido
  }
}
.bgTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(127deg, #205771 18.11%, #000 88.57%);
}
.central-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 6rem;
  position: relative;
  z-index: 1;
}
@media (max-width: 769px) {
  .central-content {
    display: flex;
    justify-content: center;
    padding: 0 0px;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}

.bgBlack {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
//PARALLAX STYLES
.parallax-section {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.parallax-image {
  position: absolute;
  will-change: transform;
  overflow: hidden;
}

.image1 {
  width: 600px;
  height: 590px;
  opacity: 0.2;
  top: 9%;
  right: 3%;
}
.image2 {
  opacity: 0.3;
  width: 470px;
  height: 470px;
  top: 18%;
  right: -10%;
}
.image3 {
  width: 600px;
  height: 590px;
  opacity: 0.3;
  top: 28%;
  right: -4%;
}
.image4 {
  opacity: 0.3;
  width: 470px;
  height: 470px;
  top: 20%;
  right: -11%;
}
.image5 {
  opacity: 0.2;
  width: 600px;
  height: 590px;
  top: 10%;
  left: -6%;
}
.image6 {
  opacity: 0.3;
  width: 470px;
  height: 470px;
  top: 10%;
  right: -10%;
}
.image7 {
  opacity: 0.2;
  width: 450px;
  height: 450px;
  bottom: 90%;
  right: -5%;
}
.image8 {
  opacity: 0.3;
  width: 470px;
  height: 470px;
  top: -107%;
  left: -5%;
}
@media (max-width: 767px) {
  .ul-lis {
    margin-top: 60px !important;
  }
  .mobile-text {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .third-container3 {
    // display: none !important;
    max-width: 700px;
    width: 100%;
    margin: 0;
    padding: 0 !important;
    line-height: 14px !important;
    height: 65px !important;
  }
  .image1 {
    width: 350px;
    height: 360px;
    opacity: 0.2;
    top: 70%;
    right: -25%;
  }
  .image2 {
    width: 300px;
    height: 300px;
    top: 55%;
    right: -20%;
  }
  .image3 {
    width: 350px;
    height: 360px;
    opacity: 0.3;
    top: 65%;
    left: -7%;
  }
  .image4 {
    opacity: 0.3;
    width: 300px;
    height: 300px;
    top: 70%;
    right: -28%;
  }
  .image5 {
    opacity: 0.2;
    width: 350px;
    height: 360px;
    top: 10%;
    left: -9%;
  }
  .image6 {
    opacity: 0.3;
    width: 300px;
    height: 300px;
    top: -30%;
    right: -40%;
  }
  .image7 {
    opacity: 0.2;
    width: 250px;
    height: 260px;
    bottom: 150%;
    right: -10%;
  }
  .image8 {
    opacity: 0.3;
    width: 300px;
    height: 300px;
    top: -150%;
    left: -5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .image1 {
    width: 400px;
    height: 390px;
    opacity: 0.2;
    top: 58%;
    right: -10%;
  }
  .image2 {
    width: 410px;
    height: 410px;
    top: 40%;
  }
  .image3 {
    width: 400px;
    height: 390px;
    opacity: 0.3;
    top: 60%;
    left: -5%;
  }
  .image4 {
    opacity: 0.3;
    width: 410px;
    height: 410px;
    top: 65%;
    right: -22%;
  }
  .image5 {
    opacity: 0.2;
    width: 400px;
    height: 390px;
    top: 20%;
    left: -6%;
  }
  .image6 {
    opacity: 0.3;
    width: 410px;
    height: 410px;
    top: 20%;
    right: -30%;
  }
  .image7 {
    opacity: 0.2;
    width: 410px;
    height: 410px;
    bottom: 95%;
    right: -5%;
  }
  .image8 {
    opacity: 0.3;
    width: 410px;
    height: 410px;
    top: -107%;
    left: -5%;
  }
}
@media (min-width: 1600px) {
  .image1 {
    width: 650px;
    height: 650px;
    top: 9%;
    right: 7%;
  }
  .image2 {
    width: 570px;
    height: 570px;
  }
  .image3 {
    opacity: 0.3;
    width: 650px;
    height: 650px;
    top: 24%;
    right: 0%;
  }
  .image4 {
    width: 570px;
    height: 570px;
    top: 25%;
  }
  .image5 {
    opacity: 0.3;
    width: 650px;
    height: 650px;
    top: 10%;
    left: -4%;
  }
  .image6 {
    width: 570px;
    height: 570px;
    top: 0%;
    right: -10%;
  }
  .image7 {
    opacity: 0.3;
    width: 570px;
    height: 570px;
    bottom: 70%;
    right: -1%;
  }
  .image8 {
    width: 570px;
    height: 570px;
    top: -100%;
    left: -8%;
  }
}
.section-5 {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .sticky-background {
    position: sticky;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .background-image1 {
      position: absolute;
      width: 140px;
      height: 420px;
      bottom: 20%;
      right: 0%;
      opacity: 0.2;
    }
    .background-image2 {
      position: absolute;
      width: 530px;
      height: 490px;
      bottom: 0%;
      left: 0%;
      opacity: 0.2;
    }
    @media (max-width: 1024px) {
      .background-image1 {
        position: absolute;
        width: 100px;
        height: 310px;
        bottom: 10%;
        right: 0%;
        opacity: 0.2;
      }
      .background-image2 {
        position: absolute;
        width: 390px;
        height: 350px;
        bottom: 0%;
        left: -7%;
        opacity: 0.2;
      }
    }
    @media (max-width: 767px) {
      .background-image1 {
        position: absolute;
        width: 70px;
        height: 250px;
        bottom: 10%;
        right: -5%;
        opacity: 0.2;
      }
      .background-image2 {
        position: absolute;
        width: 230px;
        height: 190px;
        bottom: 0%;
        left: 0%;
        opacity: 0.2;
      }
    }
  }
}
</style>
