<script setup>
import { gsap } from 'gsap'
import { onMounted, ref } from 'vue'
import linesRedBlue from '@/assets/images/parallax_imgs/linesRedBlue.svg'
import linesYellowBlue2 from '@/assets/images/parallax_imgs/linesYellowBlue2.svg'

import distribution_w from '@/assets/images/servicios_empresariales/distribution_w.svg'
import clip_dollar_w from '@/assets/images/clip_dollar_w.svg'
import data_w from '@/assets/images/servicios_empresariales/data_w.svg'
import gif04 from '@/assets/images/gifs/gif04.gif'

import AnimatedButton from '@/components/Home/AnimatedButton.vue'
import CardColor from '@/components/UserPremium/CardColor.vue'
import GridCardsCanales from '@/components/UserPremium/GridCardsCanales.vue'

/* PARRALAX */
let parallaxContainer = null
let layers = null

onMounted(() => {
  parallaxContainer = document.querySelector('.parallax-container')
  layers = document.querySelectorAll('.layer')

  gsap.set(layers, { x: 0, y: 0 }) // set initial position
  gsap.set(layers[2], { y: 1500 })
  gsap.set(layers[3], { y: 1500 })
  gsap.set(layers[4], { y: 3200 })

  parallaxContainer.addEventListener('scroll', () => {
    const scrollPosition = parallaxContainer.scrollTop
    const speed = 0.8

    gsap.to(layers[0], {
      x: -scrollPosition * speed,
      y: scrollPosition * (speed * 1.3),
      ease: 'none',
      duration: 0.7,
    })

    gsap.to(layers[1], {
      y: scrollPosition * (speed * 0.8),
      ease: 'none',
      duration: 0.8,
    })
  })
})

//DSPLAZAMIENTO AUTOMATICO
onMounted(() => {
  const sections = document.querySelectorAll('section')
  sections.forEach((section) => {
    section.addEventListener('click', () => {
      const nextSection = section.nextElementSibling
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' })
      }
    })
  })
})
//FONDO GRADIENTE
// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const elements = document.querySelectorAll('.bgTransition')

  // Definir los gradientes
  const gradient1 = `linear-gradient(127deg, #480213 18.11%, #000 88.57%)`
  const gradient2 = ` linear-gradient(72deg, #480213 5.45%, #000 100%)`
  const gradient3 = `linear-gradient(291deg, #480213 -2.43%, #000 90.78%)`

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
    .to(elements, { background: gradient2, duration: 3, ease: 'none' })
    .to(elements, { background: gradient3, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

// eslint-disable-next-line
const urlLogin = ref('https://panel.themusicdistribution.com/')
// eslint-disable-next-line
const urlSingUp = ref('https://panel.themusicdistribution.com/es/sign-up')
const currentUrl = window.location.pathname
if (currentUrl.indexOf('tmd-access') > -1) {
  urlLogin.value = 'https://distribucion.themusicdistribution.com/'
  urlSingUp.value =
    'https://distribucion.themusicdistribution.com/users/register'
}

const goToPlattform = (type) => {
  if (type === 2) {
    window.location.href = urlLogin.value
  } else {
    window.location.href = urlSingUp.value
  }
}
</script>
<template>
  <div>
    <div class="income-reports">
      <div class="parallax-container">
        <section id="section1" class="section bgTransition">
          <div class="layer-1">
            <img :src="linesRedBlue" alt="Layer 1" class="layer-image1 layer" />
            <img
              :src="linesYellowBlue2"
              alt="Layer 2"
              class="layer-image2 layer"
            />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  Integrar datos <br />
                  dsp
                </h1>
                <h4>
                  The Music Distribution le permite conectarse fácilmente a
                  todos sus conjuntos de datos a través de los servicios de
                  música digital e integrar todas sus fuentes de datos de
                  terceros.
                </h4>
                <div class="form-group">
                  <button type="button" @click="goToPlattform(1)" class="submit-button" style="text-wrap: nowrap">
                    OBTENLO AHORA
                  </button>
                </div>
              </div>
              <div class="second-container">
                <img
                  :src="gif04"
                  alt="logo-gif"
                  style="width: 100%; height: 100%; object-fit: fill"
                />
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container">
              <div class="box">
                <div class="box-div-first">
                  <span class="text"> Conectar</span>
                  <span class="sub-text">Todos los DSP</span>
                </div>
                <div class="box-div-second">
                  <span class="text">Sincroniza</span>
                  <span class="sub-text">Sus datos</span>
                </div>
                <div class="box-div-third">
                  <span class="text">Guardar</span>
                  <span class="sub-text">Tiempo y dinero</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 2 -->
        <section id="section2" class="section bgBlack">
          <div class="order-fourth">
            <div class="container">
              <div class="d-flex justify-content-center w-100">
                <div class="div-label-04">
                  <p>Integraciones</p>
                </div>
              </div>
              <h2>
                The Music Distribution está integrado con las principales
                plataformas
              </h2>
              <p>
                Vea cómo The Music Distribution simplifica las integraciones con
                los principales DSP, servicios y plataformas.
              </p>
              <div class="d-flex justify-content-center">
                <AnimatedButton></AnimatedButton>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 3 -->
        <div id="section3" class="section02 bgBlack overlay-section">
          <div class="order-fifth">
            <GridCardsCanales></GridCardsCanales>
          </div>
        </div>
      </div>
      <section
        id="section5"
        class="bgTransition z-2"
        style="height: auto !important; overflow-x: hidden"
      >
        <div
          style="
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="layer-1" style="width: 100%; height: 100%">
            <img :src="linesRedBlue" alt="Layer 1" class="img-last-01 layer" />
            <img
              :src="linesYellowBlue2"
              alt="Layer 2"
              class="img-last-02 layer"
            />
          </div>
          <div class="order" style="overflow: hidden">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  Obtener The music <br />
                  distribution
                </h1>
                <h4>y gestione hoy mismo sus integraciones de datos.</h4>
                <div class="form-group">
                  <button type="button" @click="goToPlattform(1)" class="submit-button">OBTENLO AHORA</button>
                </div>
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container-last" style="min-height: 150px">
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="distribution_w"
                  title="Distribución y entrega"
                  text="Monetizar en todas partes"
                  background="linear-gradient(138deg, #7D1426 -37.97%, #D02140 108.31%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="clip_dollar_w"
                  title="Informes de ingresos"
                  text="Simplificar la elaboración"
                  background="linear-gradient(92deg, #DA9721 -0.12%, #F2A825 100.29%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="data_w"
                  title="Almacenes de datos"
                  text="Almacenes de datos totalmente gestionados"
                  background=" linear-gradient(92deg, #2198D0 -0.12%, #1E89BB 100.29%)"
                ></CardColor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.overlay-section {
  position: relative;
}

.overlay-section::before {
  content: '';
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  height: 250px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  pointer-events: none;
  z-index: 3;
}

.bgBlack {
  width: 100%;
  height: 100vh;
}
.bgTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(72deg, #480213 5.45%, #000 100%);
}
.central-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 100px;
  position: relative;
  height: 80%;
  z-index: 1;
}
@media (max-width: 768px) {
  .central-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    height: 100vh;
  }
}

.parallax-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.layer {
  position: absolute;
  transform: translateZ(-1px);
}

.layer-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}
.layer-image1 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: -139px;
  bottom: -54px;
}

.layer-image2 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 140px;
}

@media (max-width: 768px) {
  .layer-image1 {
    width: 518px;
    height: 518px;
    opacity: 0.2;
    right: -139px;
    bottom: -54px;
  }

  .layer-image2 {
    opacity: 0.3;
    width: 240px;
    height: 385px;
    right: -150px;
    bottom: 140px;
  }
}

@media (min-width: 1600px) {
  .layer-image1 {
    opacity: 0.2;
    right: 0%;
    bottom: -10%;
  }
}
@media (max-width: 480px) {
  /* Estilos para dispositivos móviles pequeños */
  .layer-image1 {
    width: 300px;
    height: 300px;
    opacity: 0.2;
    right: -50px;
    bottom: -20px;
  }
  .layer-image2 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -70px;
    bottom: 100px;
  }
}
.img-last-01 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: 5%;
  top: -120%;
  overflow: hidden;
}
.img-last-02 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -10%;
  top: -130%;
  overflow: hidden;
}
</style>
