<script setup>
import { gsap } from 'gsap'
import { onMounted, watch, ref, onUnmounted } from 'vue'
import linesBeige from '@/assets/images/parallax_imgs/linesBeige.svg'
import linesCanario from '@/assets/images/parallax_imgs/linesCanario.svg'
import linesBYellow from '@/assets/images/parallax_imgs/linesBYellow.svg'
import linesRed from '@/assets/images/parallax_imgs/linesRed.svg'
import frame_130 from '@/assets/images/servicios_empresariales/frame_130.png'
import frame_131 from '@/assets/images/servicios_empresariales/frame_131.png'
import AnimatedButton from '@/components/BasicUser/AnimatedButton.vue'
import ImgLI from '@/assets/images/basic_user/Icon.svg'
import Amazon from '@/assets/images/basic_user/amazonmusic.svg'
import YouTube from '@/assets/images/basic_user/youtube.svg'
import Spotify from '@/assets/images/basic_user/Spotify.svg'
import Apple from '@/assets/images/basic_user/Apple.svg'
import Deezer from '@/assets/images/basic_user/Deezer.svg'
import TikTok from '@/assets/images/basic_user/tiktok.svg'
import GooglePlay from '@/assets/images/basic_user/GooglePlay.svg'
import Player from '@/assets/images/basic_user/player.gif'
import Player2 from '@/assets/images/basic_user/player2.gif'
import Tablet from '@/assets/images/basic_user/tablet.svg'
import Beatport from '@/assets/images/basic_user/beatport.svg'
import Heart from '@/assets/images/basic_user/heartradio.svg'
import Imi from '@/assets/images/basic_user/imimobile.svg'
import Jaxsta from '@/assets/images/basic_user/jaxsta.svg'
import Mixcloud from '@/assets/images/basic_user/Mixcloud.svg'
import Moink from '@/assets/images/basic_user/moinkingme.svg'
import Napster from '@/assets/images/basic_user/napster.svg'
import Pandora from '@/assets/images/basic_user/pandora.svg'
import Tidal from '@/assets/images/basic_user/Tidal.svg'
import Resso from '@/assets/images/basic_user/resso.svg'
/* PARRALAX */
let parallaxContainer = null
let layers = null
// Método para animar buttons
const animateElement = () => {
  // Seleccionar el elemento
  const element = document.querySelectorAll('.title-section-color')

  // Definir los gradientes
  const gradient1 =
    'conic-gradient(from 90deg at 50% 50%, #21090f 0%, #394c6b 30deg, #28729d 165deg, #681e32 188deg, #66101f 320deg, #360811 350deg, #100305 358deg)'
  const gradient2 =
    'conic-gradient(from -90deg at 50% 50%, #bb8419 0%, #994621 35deg, #6c1321 165deg, #480b15 188deg, #2d0c0c 310deg, #4e1c13 335deg, #bb8319 358deg)'
  const gradient3 =
    'conic-gradient(from 2deg at 50% 52.13%, #1f82b3 0%, #3f879a 50deg, #e7a118 177.36157536506653deg, #ac5028 240deg, #442224 268.28046798706055deg, #5d2e40 280deg, #247faf 320deg)'
  const gradient4 =
    'from 0deg at 50% 50%, #21090f 0%, #681e32 50deg, #394c6b 80deg, #28729d 98deg, #2180b1 150deg, #94192f 280deg, #360811 350deg, #100305 360deg)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(element, { color: gradient1, duration: 3, ease: 'none' })
    .to(element, { color: gradient2, duration: 3, ease: 'none' })
    .to(element, { color: gradient3, duration: 3, ease: 'none' })
    .to(element, { color: gradient4, duration: 3, ease: 'none' })
  /*
  gsap.to(element, {
    delay: 0,
    color: gradient4, // Establecer el gradiente final
    duration: 5, // Duración de la animación en segundos
    repeat: -1, // Repetir infinitamente
    repeatDelay: 0,
    yoyo: false, // Reproducir la animación hacia atrás y hacia adelante
    ease: 'none', // Tipo de interpolación para una transición lineal
    keyframes: [
      { color: gradient1 }, // Primer gradiente
      { color: gradient2 }, // Segundo gradiente
      { color: gradient3 }, // Tercer gradiente
      { color: gradient4 }, // Cuarto gradiente
    ],
  }) */
  gsap.fromTo('.box1', { y: 120 }, { y: 0, duration: 1 })
  gsap.fromTo('.box2', { y: -120 }, { y: 0, duration: 1 })
  gsap.fromTo('.ul-lis', { y: -120 }, { y: 5, duration: 1 })
  gsap.to('#titleMusic', { duration: 1, text: 'MUSIC', delay: 1 })
}
onMounted(() => {
  animateElement()
  parallaxContainer = document.querySelector('.parallax-container')
  layers = document.querySelectorAll('.layer')

  gsap.set(layers, { x: 0, y: 0 }) // set initial position
  gsap.set(layers[2], { y: 1500 })
  gsap.set(layers[3], { y: 1500 })
  gsap.set(layers[4], { y: 3200 })

  parallaxContainer.addEventListener('scroll', () => {
    const scrollPosition = parallaxContainer.scrollTop
    const speed = 0.8

    if (scrollPosition > 980) {
      gsap.to(layers[0], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[0], {
        x: -scrollPosition * speed,
        y: scrollPosition * (speed * 1.3),
        ease: 'none',
        duration: 0.7,
      })
    }

    if (scrollPosition > 900) {
      gsap.to(layers[1], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[1], {
        y: scrollPosition * (speed * 0.6),
        ease: 'none',
        duration: 0.6,
      })
    }

    if (scrollPosition > 2900) {
      gsap.to(layers[2], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[2], {
        y: scrollPosition * (speed * 0.7),
        ease: 'none',
        duration: 0.8,
      })
    }

    if (scrollPosition > 2700) {
      gsap.to(layers[3], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[3], {
        y: scrollPosition * (speed * 1.1),
        ease: 'none',
        duration: 0.8,
      })
    }

    gsap.to(layers[4], {
      x: -scrollPosition * (speed * 0.5),
      ease: 'none',
      duration: 0.8,
    })
  })
})

//DSPLAZAMIENTO AUTOMATICO
onMounted(() => {
  const sections = document.querySelectorAll('section')
  sections.forEach((section) => {
    section.addEventListener('click', () => {
      const nextSection = section.nextElementSibling
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' })
      }
    })
  })
})
//FONDO GRADIENTE
// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const elements = document.querySelectorAll('.bgTransition')
  // Definir los gradientes
  const gradient1 = 'linear-gradient(114deg, #480213 -1.23%, #000 84.52%)'
  const gradient2 = 'linear-gradient(73deg, #480213 -2.48%, #000 84.96%)'
  const gradient3 =
    'linear-gradient(144deg, rgba(179, 59, 61, 0.8) 0%, rgba(72, 2, 20, 1) 100%)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
    .to(elements, { background: gradient2, duration: 3, ease: 'none' })
    .to(elements, { background: gradient3, duration: 3, ease: 'none' })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

//Cambiar la imagen dependiendo la pantalla
// Estado reactivo para el ancho de pantalla
const anchoPantalla = ref(window.innerWidth)

const actualizarAnchoPantalla = () => {
  anchoPantalla.value = window.innerWidth
  console.log('Ancho de pantalla actualizado:', anchoPantalla.value)
}

onMounted(() => {
  window.addEventListener('resize', actualizarAnchoPantalla)
  console.log('Componente montado, ancho inicial:', anchoPantalla.value)
})

onUnmounted(() => {
  window.removeEventListener('resize', actualizarAnchoPantalla)
})

const imagen = ref(anchoPantalla.value <= 1024 ? frame_131 : frame_130)

watch(anchoPantalla, (nuevoValor) => {
  console.log('Nuevo valor de ancho de pantalla:', nuevoValor)
  if (nuevoValor <= 1024) {
    imagen.value = frame_131
  } else {
    imagen.value = frame_130
  }
  console.log('Imagen actual:', imagen.value)
})
</script>
<template>
  <div>
    <div class="distribution-delivery">
      <div class="parallax-container pb-5">
        <section id="section1" class="section bgTransition pb-5">
          <div class="layer-1">
            <img :src="linesBeige" alt="Layer 1" class="layer-image1 layer" />
            <img :src="linesCanario" alt="Layer 2" class="layer-image2 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <div class="title-section box1">THE</div>
                <h1 id="titleMusic" class="title-section title-section-color">
                  CISUM
                </h1>
                <div class="title-section box2">DISTRIBUTION</div>
                <div class="ul-lis">
                  <ul
                    class="ul-first"
                    :style="'list-style-image:url(' + ImgLI + ');'"
                  >
                    <li class="txt-li">Registro sin costo</li>
                    <li class="txt-li">Acceso a estadísticas</li>
                    <li class="txt-li">Alcance Global</li>
                    <li class="txt-li">Control total</li>
                  </ul>
                </div>
                <div class="ul-lis">
                  <div class="container">
                    <div class="img-order">
                      <div class="col">
                        <img :src="Spotify" alt="Spotify" class="" />
                      </div>
                      <div class="col">
                        <img :src="Apple" alt="Apple Music" class="" />
                      </div>
                      <div class="col">
                        <img :src="YouTube" alt="YouTube" class="" />
                      </div>
                      <div class="col">
                        <img :src="Deezer" alt="Deezer" class="" />
                      </div>
                      <div class="col">
                        <img :src="TikTok" alt="TikTok" class="" />
                      </div>
                      <div class="col">
                        <img :src="GooglePlay" alt="GooglePlay" class="" />
                      </div>
                      <div class="col">
                        <img :src="Amazon" alt="Amazon Music" class="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECOND SECTION -->
        <section id="section2" class="section bgBlack">
          <div class="layer-2">
            <img :src="linesBYellow" alt="Layer 3" class="layer-image3 layer" />
            <img :src="linesRed" alt="Layer 4" class="layer-image4 layer" />
          </div>
          <div class="info-container">
            <p class="title-subsection">¿Cómo funciona?</p>
            <div class="container text-center sizing">
              <div class="row">
                <div class="col">
                  <p class="subtitle_subsection">Registra una cuenta</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p class="description_subsection">
                    Sube una cantidad ilimitada de singles, EPs y álbumes. Lanza
                    tus canciones en más de un centenar de plataformas de
                    streaming musical.
                  </p>
                </div>
              </div>
              <!--div class="row">
              <div class="col">
                <div class="ul-lis">
                  <ul class="text-white logos-music" style="text-decoration: none;">
                    <li class="music-logos-li back-li">
                      <img :src="Spotify" alt="Spotify" class="" />
                    </li>
                    <li class="music-logos-li back-li">
                      <img :src="Apple" alt="Apple Music" class="" />
                    </li>
                    <li class="music-logos-li back-li">
                      <img :src="TikTok" alt="YouTube" class="" />
                    </li>
                    <li class="music-logos-li back-li">
                      <img :src="Deezer" alt="Deezer" class="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div-->
              <div class="row">
                <div class="col">
                  <img class="img_player" :src="Player" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <AnimatedButton></AnimatedButton>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 3 -->
        <section id="section3" class="section bgBlack pt-5">
          <!-- <div class="layer-3">
            <img :src="linesRed" alt="Layer 5" class="layer-image5 layer" />
          </div> -->
          <div class="container info-container">
            <div class="row">
              <div class="col" style="margin-top: 0%">
                <p class="subtitle_subsection">Pagas cuando ganas</p>
              </div>
            </div>
            <div class="row-to-col">
              <div class="col">
                <ul class="text-white">
                  <li class="music-logos-li">
                    <span>Transparencia total:</span> solo pagas un 15% de
                    regalías generales cuando ganas, sin costos iniciales ni
                    tarifas ocultas.
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="text-white">
                  <li class="music-logos-li m-0">
                    <span>Éxito compartidos:</span>
                    Nuestro modelo de precios alinea nuestras tarifas con tu
                    éxito, garantizando una relación justa y transparente.
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-center">
              <div class="col">
                <img class="img_player" :src="Player2" />
                <AnimatedButton></AnimatedButton>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 4 -->
        <section id="section4" class="section bgBlack section-shadow">
          <div
            class="container info-container justify-content-lg-around justify-content-evenly gap-2 gap-md-4"
          >
            <div class="mx-2">
              <div class="row text-center">
                <div class="col">
                  <p class="subtitle_subsection">
                    Acceso Completo a Estadísticas
                  </p>
                </div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <p class="description_subsection">
                    Registre una cuenta de distribución de forma muy fácil y
                    rápida. Configure su perfil y comience a trabajar en su
                    producto.
                  </p>
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col d-flex flex-column gap-2">
                <img class="img_tablet" :src="Tablet" />
              </div>
            </div>
          </div>
          <div class="animation-tablet">
            <AnimatedButton></AnimatedButton>
          </div>
        </section>
        <section
          id="section5"
          class="section bgBlack"
          style="background: black"
        >
          <div class="container info-container">
            <div class="row text-center mb-5">
              <div class="col mt-3">
                <p class="title-subsection">DISTRIBUYE TU MÚSICA,</p>
                <p class="title-subsection2">CONQUISTA EL MUNDO</p>
              </div>
            </div>
            <div class="img-order-2">
              <div class="col">
                <img :src="Spotify" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Apple" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="YouTube" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Deezer" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Amazon" alt="Spotify" class="" />
              </div>

              <div class="col">
                <img :src="TikTok" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Resso" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Beatport" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Heart" alt="Spotify" class="" />
              </div>
              <div class="col"><img :src="Imi" alt="Spotify" class="" /></div>

              <div class="col">
                <img :src="GooglePlay" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Pandora" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Tidal" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Mixcloud" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Moink" alt="Spotify" class="" />
              </div>

              <div class="col">
                <img :src="Napster" alt="Spotify" class="" />
              </div>
              <div class="col">
                <img :src="Jaxsta" alt="Spotify" class="" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        id="section5"
        class="bgTransition overlay-section2 z-2"
        style="height: 100% !important; overflow: hidden"
      >
        <div style="height: 100%; width: 100%">
          <div class="layer-1" style="width: 100%; height: 100%">
            <img :src="linesBeige" alt="Layer 1" class="img-last-01 layer" />
            <img :src="linesBeige" alt="Layer 2" class="img-last-03 layer" />
            <img :src="linesCanario" alt="Layer 2" class="img-last-02 layer" />
          </div>
          <div class="order">
            <div
              class="central-content h-100"
              style="padding: 5% 0%; width: 60%; justify-content: flex-start"
            >
              <div class="first-container">
                <h1>
                  ÚNETE AHORA, DESCUBRE EL<br />
                  POTENCIAL ILIMITADO
                </h1>
                <h4>
                  Regístrate ahora y comienza tu viaje hacia el éxito<br />
                  musical independiente.
                </h4>
                <div class="form-group">
                  <button class="submit-button">REGISTRATE AHORA</button>
                </div>
              </div>
            </div>
            <!-- *Se esconde en phone-->
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.section-shadow {
  position: relative;
}

.section-shadow::after {
  content: '';
  position: absolute;
  bottom: -20px; // Ajusta según sea necesario
  left: 0;
  right: 0;
  height: 45%; // Ajusta según sea necesario
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  z-index: 2;
}
.first-container {
  text-align: left !important;
  gap: 1.5rem !important;
  margin: 0;
}
@media (max-width: 768px) {
  .first-container {
    gap: 3rem !important;
  }
}
@media (max-width: 480px) {
  .first-container {
    gap: 1rem !important;
    margin: 0;
  }
}

.img_player {
  width: 100%;
  max-width: 450px;
}
@media (max-width: 400px) {
  .img_player {
    width: 80%;
    height: auto;
  }
}

.img_tablet {
  width: 100%;
  max-width: 850px;
}

.animation-tablet {
  position: relative;
  z-index: 3 !important;
}
@media (max-width: 400px) {
  .animation-tablet {
    position: relative;
    z-index: 3 !important;
    top: -17%;
  }
}

.back-li {
  background-color: #000000;
  padding: 20px;
  border-radius: 5px;
  opacity: 1;
  z-index: 999;
}
.title-section {
  font-family: 'Oswald', sans-serif;
  font-size: 145px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

@media (max-width: 853px) {
  .title-section {
    font-size: 100px;
  }
}
@media (max-width: 480px) {
  .title-section {
    font-size: 3.5rem;
  }
}
@media (max-width: 400px) {
  .title-section {
    font-size: 3rem;
  }
}

.title-section-color {
  // background: -webkit-linear-gradient(127.23deg, #480213 18.11%, #000000 88.57%);
  background: conic-gradient(
    from 180deg at 50% 52.13%,
    #1f7aa7 0deg,
    rgba(208, 33, 64, 0.5) 177.36deg,
    rgba(208, 33, 64, 0) 268.28deg,
    #1f7aa7 360deg
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.info-container {
  padding-top: 8%;
  max-width: 1100px;
  width: 100%;
  z-index: 2;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .info-container {
    padding: 8% 5% 0% 5%;
    max-width: 1100px;
    width: 100%;
    z-index: 2;
  }
}
@media (max-width: 430px) {
  .info-container {
    display: flex;
    flex-direction: column;
    padding: 20% 2% 0% 2%;
    max-width: 1100px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    z-index: 2;
    align-items: center;
    justify-content: center;
  }
}

.row-to-col {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .row-to-col {
    display: block;
  }
}
.space {
  padding-top: 0;
}
@media (max-width: 400px) {
  .space {
    padding-top: 10%;
  }
}
.container-section {
  text-align: center;
  max-width: 650px;
  width: 100%;
}

.sizing {
  width: 75%;
}
@media (max-width: 853px) {
  .sizing {
    width: 100%;
  }
}

.title-subsection {
  align-self: stretch;
  font-family: 'Oswald', sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 480px) {
  .title-subsection {
    text-align: center;
    font-size: 45px;
    margin: 0;
  }
}

.title-subsection2 {
  align-self: stretch;
  background: -webkit-linear-gradient(91.62deg, #21d0b0 24.59%, #21a6d0 78.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Oswald', sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}
.subtitle_subsection {
  color: #ffffff;
  text-align: left;
  font-family: 'DM Serif Display', serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media (max-width: 480px) {
  .subtitle_subsection {
    font-size: 30px;
    margin: 0;
  }
}
.description_subsection {
  font-family: Poppins;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left;
  color: #fff;
  margin: 0;
}
.img-order {
  display: flex;
  flex-direction: row;
  color: white;
  gap: 2rem;
}
.img-order-2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-gap: 2rem; /* Space between items */
  color: white;
  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .img-order-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 columns */
    grid-gap: 2rem; /* Space between items */
    color: white;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 5rem;
        height: auto;
      }
    }
  }
}
@media (max-width: 400px) {
  .img-order-2 {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns */
    grid-gap: 2rem; /* Space between items */
    color: white;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 5rem;
        height: auto;
      }
    }
  }
}

.ul-lis {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 1024px) {
  .img-order {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .img-order {
    flex-wrap: wrap;
    gap: 1rem;
    img {
      width: 4rem;
      height: auto;
    }
  }
  .ul-lis {
    text-align: center;
    margin: 0px auto;
    width: 100%;
  }
}
.txt-li {
  margin-right: 60px;
  text-wrap: nowrap;
}
@media (max-width: 820px) {
  .txt-li {
    margin-right: 50px;
    text-wrap: nowrap;
  }
}
@media (max-width: 400px) {
  .txt-li {
    margin-right: 30px;
    font-size: small;
    text-wrap: nowrap;
  }
}

.music-logos-li {
  margin-right: 60px;
}
.logos-music {
  list-style: none;
  display: flex;
}
.ul-first {
  display: flex;
}
.overlay-section2 {
  position: relative;
}

.overlay-section {
  position: relative;
}

.overlay-section::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 293px; /* Ajusta la altura del resplandor */
  // background: linear-gradient(127.23deg, #480213 18.11%, #000000 88.57%);
  background: conic-gradient(
    from 180deg at 50% 52.13%,
    #1f7aa7 0deg,
    rgba(208, 33, 64, 0.5) 177.36deg,
    rgba(208, 33, 64, 0) 268.28deg,
    #1f7aa7 360deg
  );
  // background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #c77729 100%);
  pointer-events: none;
  opacity: 0.56;
  z-index: 3 !important; /* Asegura que el resplandor esté sobre el contenido */
}

.bgBlack {
  width: 100%;
  height: 100vh !important;
}
.bgTransition {
  width: 100%;
  height: 310vh;
  background: linear-gradient(127.23deg, #480213 18.11%, #000000 88.57%);
  // background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #c77729 100%);
}
.central-content {
  flex-direction: row;
  justify-content: center;
  padding: 0 100px;
  padding-top: 7%;
  position: relative;
  z-index: 1;
}
@media (max-width: 1024px) {
  .central-content {
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 5%;
    padding-top: 10%;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
@media (max-width: 768px) {
  .central-content {
    padding-top: 15%;
    height: 100%;
  }
}
@media (max-width: 400px) {
  .central-content {
    gap: 20px;
    padding-top: 25%;
    height: 100%;
  }
}

.parallax-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.layer {
  position: absolute;
  transform: translateZ(-1px);
}

.layer-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}
.layer-image1 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: -139px;
  bottom: -54px;
}

.layer-image2 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 140px;
}
.layer-image3 {
  width: 618px;
  height: 618px;
  left: -100px;
  bottom: -45%;
  opacity: 0.3;
}
.layer-image4 {
  width: 340px;
  height: 485px;
  right: -120px;
  bottom: -100px;
  opacity: 0.8;
}
.layer-image5 {
  opacity: 0.5;
  width: 340px;
  height: 485px;
  right: -90px;
  top: -280px;
  z-index: 2;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .layer-image5 {
    right: -40%;
    top: -55%;
  }
}
@media (min-width: 1600px) {
  .layer-image1 {
    opacity: 0.2;
    right: 0%;
    bottom: -10%;
  }
  .layer-image4 {
    right: -120px;
    bottom: -20%;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    right: 15%;
    top: -50%;
    z-index: 1;
  }
}
@media (max-width: 768px) {
  .first-container {
    text-align: left !important;
    align-items: baseline !important;
  }
  .bgBlack {
    width: 100%;
    height: 1000px !important;
  }
  .ul-first {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    gap: 4% 2%;
  }
  .logos-music {
    display: inline;
  }
  /* .title-section {
    font-family: 'Oswald', sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
  } */

  .layer-image1 {
    width: 518px;
    height: 518px;
    opacity: 0.2;
    right: -139px;
    bottom: -54px;
  }

  .layer-image2 {
    opacity: 0.3;
    width: 240px;
    height: 385px;
    right: -150px;
    bottom: 140px;
  }
  .layer-image3 {
    width: 508px;
    height: 508px;
    left: -100px;
    bottom: -45%;
    opacity: 0.3;
  }
  .layer-image4 {
    width: 240px;
    height: 385px;
    right: -120px;
    bottom: -100px;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    width: 240px;
    height: 385px;
    right: -85%;
    top: -280px;
  }
  .title-subsection {
    align-self: stretch;
    font-family: 'Oswald', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3;
    text-transform: uppercase;
  }
  .title-subsection2 {
    align-self: stretch;
    background: -webkit-linear-gradient(
      91.62deg,
      #21d0b0 24.59%,
      #21a6d0 78.91%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Oswald', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3;
    text-transform: uppercase;
  }
}
@media (max-width: 480px) {
  /* Estilos para dispositivos móviles pequeños */
  .first-container {
    text-align: left;
    align-items: baseline;
  }
  .layer-image1 {
    width: 300px;
    height: 300px;
    opacity: 0.2;
    right: -50px;
    bottom: -20px;
  }
  .layer-image2 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -70px;
    bottom: 100px;
  }
  .layer-image3 {
    width: 300px;
    height: 300px;
    left: -50px;
    bottom: -20%;
    opacity: 0.5;
  }
  .layer-image4 {
    width: 180px;
    height: 280px;
    right: -60px;
    bottom: -50px;
    opacity: 0.8;
  }
  .layer-image5 {
    opacity: 0.5;
    width: 180px;
    height: 280px;
    right: -260%;
    top: -200px;
  }
}
.img-last-03 {
  opacity: 0.2;
  width: 618px;
  height: 618px;
  right: -5%;
  bottom: -20%;
}
.img-last-02 {
  opacity: 0.2;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 10%;
  overflow: hidden;
}

.distribution-delivery {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  min-height: 200vh;
  section {
    max-width: 100%;
    align-items: center;
    padding: 0;
    height: 100vh !important;
  }
  .order {
    /* border: 2px solid yellow; */
    align-items: center;
    justify-content: center;
    /* padding-top: 140px; */
    padding: 0;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  @media (min-width: 1600px) {
    .order {
      width: 100%;
    }
  }
}
</style>
