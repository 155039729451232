<script setup>
import { gsap } from 'gsap'
import { onMounted } from 'vue'

// Método para animar buttons
const animateElement = () => {
  // Seleccionar el elemento
  const element = document.querySelectorAll('.firstTransition')

  // Definir los gradientes
  const gradient1 =
    'conic-gradient(from 90deg at 50% 50%, #21090f 0%, #394c6b 30deg, #28729d 165deg, #681e32 188deg, #66101f 320deg, #360811 350deg, #100305 358deg)'
  const gradient2 =
    'conic-gradient(from -90deg at 50% 50%, #bb8419 0%, #994621 35deg, #6c1321 165deg, #480b15 188deg, #2d0c0c 310deg, #4e1c13 335deg, #bb8319 358deg)'
  const gradient3 =
    'conic-gradient(from 2deg at 50% 52.13%, #1f82b3 0%, #3f879a 50deg, #e7a118 177.36157536506653deg, #ac5028 240deg, #442224 268.28046798706055deg, #5d2e40 280deg, #247faf 320deg)'
  const gradient4 =
    'from 0deg at 50% 50%, #21090f 0%, #681e32 50deg, #394c6b 80deg, #28729d 98deg, #2180b1 150deg, #94192f 280deg, #360811 350deg, #100305 360deg)'

  // Crear la animación con GSAP
  gsap.to(element, {
    delay: 0,
    background: gradient4, // Establecer el gradiente final
    duration: 30, // Duración de la animación en segundos
    repeat: -1, // Repetir infinitamente
    repeatDelay: 0,
    yoyo: false, // Reproducir la animación hacia atrás y hacia adelante
    ease: 'none', // Tipo de interpolación para una transición lineal
    keyframes: [
      { background: gradient1 }, // Primer gradiente
      { background: gradient2 }, // Segundo gradiente
      { background: gradient3 }, // Tercer gradiente
      { background: gradient4 }, // Cuarto gradiente
    ],
  })
}
// Método para llamar a la animación cuando el componente se monta
onMounted(() => {
  animateElement()
})
const goConnect = () => {
  window.location.href = 'https://panel.themusicdistribution.com/'
}
</script>
<template>
  <div class="base-button firstTransition">
    <button type="button" @click="goConnect">CONÉCTATE</button>
  </div>
</template>
<style scoped lang="scss"></style>
