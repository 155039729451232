<script setup>
import CardCanales from '@/components/UserPremium/CardCanales.vue'
import siete_digital from '@/assets/images/logos_canales/siete_digital.png'
import acrcloud from '@/assets/images/logos_canales/acrcloud.png'
import akazoo from '@/assets/images/logos_canales/akazoo.png'
import amazon_m from '@/assets/images/logos_canales/amazon_m.svg'
import anghami from '@/assets/images/logos_canales/anghami.png'
import awa from '@/assets/images/logos_canales/awa.svg'
import audiomack from '@/assets/images/logos_canales/audiomack.svg'
import bandcamp from '@/assets/images/logos_canales/bandcamp.png'
import boomplay from '@/assets/images/logos_canales/boomplay.svg'
import napster from '@/assets/images/logos_canales/napster.svg'
import pandora from '@/assets/images/logos_canales/pandora.svg'
import resso from '@/assets/images/logos_canales/resso.png'
import youtube_m from '@/assets/images/logos_canales/youtube_m.svg'
import vevo from '@/assets/images/logos_canales/vevo.svg'
import roxi from '@/assets/images/logos_canales/roxi.png'
import slacker_radio from '@/assets/images/logos_canales/slacker_radio.png'
import siriusxm from '@/assets/images/logos_canales/siriusxm.svg'
import pretzel from '@/assets/images/logos_canales/pretzel.svg'
import peloton from '@/assets/images/logos_canales/peloton.svg'
import shazam from '@/assets/images/logos_canales/shazam.png'
import povu from '@/assets/images/logos_canales/povu.png'
import SAAVN from '@/assets/images/logos_canales/SAAVN.png'
import tidal from '@/assets/images/logos_canales/tidal.svg'
import jaxta from '@/assets/images/logos_canales/jaxta.svg'
import beatport from '@/assets/images/logos_canales/beatport.svg'
import mixbank from '@/assets/images/logos_canales/mixbank.png'
import mixcloud from '@/assets/images/logos_canales/mixcloud.svg'
import cdbaby from '@/assets/images/logos_canales/cdbaby.svg'
import joox from '@/assets/images/logos_canales/joox.png'
import kkbox from '@/assets/images/logos_canales/kkbox.png'
import facebook from '@/assets/images/logos_canales/facebook.svg'
import flo from '@/assets/images/logos_canales/flo.svg'
import KDIGITAL from '@/assets/images/logos_canales/KDIGITAL.png'

const cards = [
  {
    imgSrc: siete_digital,
    text: 'Distribucion',
  },
  {
    imgSrc: acrcloud,
    text: 'Distribucion',
  },
  {
    imgSrc: akazoo,
    text: 'Distribucion',
  },
  {
    imgSrc: amazon_m,
    text: 'Distribucion',
  },
  {
    imgSrc: anghami,
    text: 'Distribucion',
  },
  {
    imgSrc: awa,
    text: 'Distribucion',
  },
  {
    imgSrc: audiomack,
    text: 'Distribucion',
  },
  {
    imgSrc: bandcamp,
    text: 'Distribucion',
  },
  {
    imgSrc: boomplay,
    text: 'Distribucion',
  },
  {
    imgSrc: napster,
    text: 'Distribucion',
  },
  {
    imgSrc: pandora,
    text: 'Distribucion',
  },
  {
    imgSrc: resso,
    text: 'Distribucion',
  },
  {
    imgSrc: youtube_m,
    text: 'Distribucion',
  },
  {
    imgSrc: vevo,
    text: 'Distribucion',
  },
  {
    imgSrc: roxi,
    text: 'Distribucion',
  },
  {
    imgSrc: slacker_radio,
    text: 'Distribucion',
  },
  {
    imgSrc: siriusxm,
    text: 'Distribucion',
  },
  {
    imgSrc: pretzel,
    text: 'Distribucion',
  },
  {
    imgSrc: peloton,
    text: 'Distribucion',
  },
  {
    imgSrc: shazam,
    text: 'Distribucion',
  },
  {
    imgSrc: povu,
    text: 'Distribucion',
  },
  {
    imgSrc: SAAVN,
    text: 'Distribucion',
  },
  {
    imgSrc: tidal,
    text: 'Distribucion',
  },
  {
    imgSrc: jaxta,
    text: 'Distribucion',
  },
  {
    imgSrc: beatport,
    text: 'Distribucion',
  },
  {
    imgSrc: mixbank,
    text: 'Distribucion',
  },
  {
    imgSrc: mixcloud,
    text: 'Distribucion',
  },
  {
    imgSrc: cdbaby,
    text: 'Distribucion',
  },
  {
    imgSrc: joox,
    text: 'Distribucion',
  },
  {
    imgSrc: kkbox,
    text: 'Distribucion',
  },
  {
    imgSrc: facebook,
    text: 'Distribucion',
  },
  {
    imgSrc: flo,
    text: 'Distribucion',
  },
  {
    imgSrc: KDIGITAL,
    text: 'Distribucion',
  },
]
</script>
<template>
  <div class="container">
    <div class="grid-container">
      <CardCanales
        v-for="(card, index) in cards"
        :key="index"
        :imgSrc="card.imgSrc"
        :text="card.text"
      ></CardCanales>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #111111;
  width: 100%;
  justify-content: center;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px; /* Espacio entre las cards */
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .grid-container {
    height: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .grid-container {
    max-height: auto;
    overflow-y: auto;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columnas para pantallas medianas */
  }
}
@media (max-width: 425px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .grid-container {
    max-height: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

h2 {
  color: $white;
  text-align: center;
  font-family: $font-family-DM;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media (max-width: 768px) {
  h2 {
    color: $white;
    text-align: center;
    font-family: $font-family-DM;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
  }
}
</style>
