<script setup>
import { defineProps } from 'vue'
import lines_w from '@/assets/images/servicios_empresariales/lines_w.svg'
import arrow_right from '@/assets/images/arrow_right.svg'

const props = defineProps({
  imgSrc: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: false,
  },
  text: {
    type: String,
    required: true,
  },
  background: {
    type: String,
    default: 'linear-gradient(to bottom, #ff0000, #ffffff)',
  },
})
</script>
<template>
  <div class="card-02" :style="{ background: props.background }">
    <div class="card-02-img">
      <img :src="props.imgSrc" alt="image" class="card-02-img-img" />
    </div>
    <div>
      <h5 style="font-weight: 600">{{ props.title }}</h5>
      <p>{{ props.text }}</p>
    </div>
    <div class="card-02-footer">
      <div class="reveal">
        <a :href="props.url" target="_blank">
          <img
            :src="arrow_right"
            alt="arrow"
            style="height: 35px; height: 35px"
          />
        </a>
      </div>
    </div>
    <img :src="lines_w" alt="lines" class="lines" />
  </div>
</template>
<style lang="scss" scoped>
.lines {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
@media (max-width: 767px) {
  .lines {
    width: 80px;
    height: 80px;
  }
}
.card-02 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 5px 30px;
  justify-content: space-evenly;
  align-items: start;
  gap: 0;
  align-self: stretch;
  width: 400px;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  z-index: 2;
  &-img {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  p,
  h5 {
    margin: 0;
  }
}
.card-02-footer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}

.reveal {
  padding: 15px;
  background-color: transparent;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-02:hover .card-02-footer {
  height: 60px;
}
.card-02:hover .reveal {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 400px) {
  .card-02 {
    width: 100%;
    height: auto;
    &-img {
      width: 20px;
      height: 20px;
    }
  }
  h5 {
    font-size: 16px;
  }
  p {
    display: none;
  }
  .lines {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767px) {
  .card-02 {
    height: 60%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .card-02 {
    width: 100%;
    min-height: 200px;
    height: 100%;
    line-height: 25px;
    gap: 10px;
    h5 {
      padding-bottom: 8px;
    }
  }
}
</style>
