import axios from 'axios'

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const sendEmailPub = (payload) => {
  return axios.post('https://themusicdistribution.com/mail.php', payload)
}

/**
 * Function to get token
 * @param {Object} payload
 * @return AxiosPromise
 */
export const sendEmailSubs = (payload) => {
  return axios.post('https://themusicdistribution.com/subscribe.php', payload)
}