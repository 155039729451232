<script setup>
import { gsap } from 'gsap'
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import logotmd from '@/assets/images/logotmd.png'
import TMD from '@/assets/images/TMD.png'
import btn_lines from '@/assets/images/btn_lines.svg'
import x_menu from '@/assets/images/x_menu.svg'
import arrow_down from '@/assets/images/arrow_down.svg'
import catalogos from '@/assets/images/servicios_empresariales/catalogos.svg'
import distribution from '@/assets/images/servicios_empresariales/distribution.svg'
import ingresos from '@/assets/images/servicios_empresariales/ingresos.svg'
import integraciones from '@/assets/images/servicios_empresariales/integraciones.svg'
import data from '@/assets/images/servicios_empresariales/data.svg'
import group from '@/assets/images/servicios_empresariales/group.svg'
import contratos from '@/assets/images/servicios_empresariales/contratos.svg'
import { Collapse } from 'bootstrap'
/* eslint-disable */
const router = useRouter()
/* import bg_1 from '@/assets/images/servicios_empresariales/bg_1.svg' */
// Método para animar buttons
const animateElement = () => {
  // Seleccionar el elemento
  const element = document.querySelectorAll('.firstTransition')

  // Definir los gradientes
  const gradient1 =
    'conic-gradient(from 90deg at 50% 50%, #21090f 0%, #394c6b 30deg, #28729d 165deg, #681e32 188deg, #66101f 320deg, #360811 350deg, #100305 358deg)'
  const gradient2 =
    'conic-gradient(from -90deg at 50% 50%, #bb8419 0%, #994621 35deg, #6c1321 165deg, #480b15 188deg, #2d0c0c 310deg, #4e1c13 335deg, #bb8319 358deg)'
  const gradient3 =
    'conic-gradient(from 2deg at 50% 52.13%, #1f82b3 0%, #3f879a 50deg, #e7a118 177.36157536506653deg, #ac5028 240deg, #442224 268.28046798706055deg, #5d2e40 280deg, #247faf 320deg)'
  const gradient4 =
    'from 0deg at 50% 50%, #21090f 0%, #681e32 50deg, #394c6b 80deg, #28729d 98deg, #2180b1 150deg, #94192f 280deg, #360811 350deg, #100305 360deg)'

  // Crear la animación con GSAP
  gsap.to(element, {
    delay: 0,
    background: gradient4, // Establecer el gradiente final
    duration: 40, // Duración de la animación en segundos
    repeat: -1, // Repetir infinitamente
    repeatDelay: 0,
    yoyo: false, // Reproducir la animación hacia atrás y hacia adelante
    ease: 'none', // Tipo de interpolación para una transición lineal
    keyframes: [
      { background: gradient1 }, // Primer gradiente
      { background: gradient2 }, // Segundo gradiente
      { background: gradient3 }, // Tercer gradiente
      { background: gradient4 }, // Cuarto gradiente
    ],
  })
}

// Método para llamar a la animación cuando el componente se monta
onMounted(() => {
  animateElement()
  const header = document.querySelector('.header')
  const targetSections = document.querySelectorAll('.target-section') // Ajustar el selector para las secciones 5 y 6

  const observer = new IntersectionObserver(
    (entries) => {
      const anyIntersecting = entries.some((entry) => entry.isIntersecting)
      if (anyIntersecting) {
        header.classList.remove('transparent')
        header.classList.add('black-transparent')
      } else {
        header.classList.remove('black-transparent')
        header.classList.add('transparent')
      }
    },
    { threshold: 0.5 }
  )

  targetSections.forEach((section) => observer.observe(section))
})

const isTabletOrSmaller = ref(false)
const isMenuVisible = ref(false)
let activeCollapse = ref(null)

const updateMediaQuery = () => {
  const isMdScreen = window.matchMedia(
    '(min-width: 992px) and (max-width: 1024px)'
  ).matches
  const isSmallerScreen = window.matchMedia('(max-width: 991px)').matches
  if (isMdScreen) {
    isTabletOrSmaller.value = true
  }
  if (isSmallerScreen || isMdScreen) {
    isTabletOrSmaller.value = true
  } else {
    isTabletOrSmaller.value = false
  }

  if (!isTabletOrSmaller.value) {
    isMenuVisible.value = false
  }
}

const toggleMenu = () => {
  /* isMenuVisible.value = !isMenuVisible.value */
  if (isTabletOrSmaller.value) {
    // Si la pantalla es más pequeña que 1024px, mostrar o ocultar el menú
    isMenuVisible.value = !isMenuVisible.value
  }
}

onMounted(() => {
  updateMediaQuery()
  window.addEventListener('resize', updateMediaQuery)
})

const toggleCollapse = (collapseId) => {
  activeCollapse.value = activeCollapse.value === collapseId ? null : collapseId
}

watch(activeCollapse, (newCollapse, oldCollapse) => {
  if (newCollapse !== oldCollapse) {
    // Hide the previously active collapse
    const oldElement = document.getElementById(oldCollapse)
    if (oldElement) {
      oldElement.classList.remove('show')
    }

    // Show the newly active collapse
    const newElement = document.getElementById(newCollapse)
    if (newElement) {
      newElement.classList.add('show')
    }
  }
})
//BOTONES rutas
const goToCatalogos = (link) => {
  window.location.href = link
}

// eslint-disable-next-line
const urlLogin = ref('https://panel.themusicdistribution.com/')
// eslint-disable-next-line
const urlSingUp = ref('https://panel.themusicdistribution.com/es/sign-up')
const isPremium = ref(true)
const currentUrl = window.location.pathname
if (currentUrl.indexOf('tmd-access') > -1) {
  isPremium.value = false
  urlLogin.value = 'https://distribucion.themusicdistribution.com/'
  urlSingUp.value =
    'https://distribucion.themusicdistribution.com/users/register'
}

const goToPlattform = (type) => {
  if (type === 2) {
    window.location.href = urlLogin.value
  } else {
    window.location.href = urlSingUp.value
  }
}
const goToDistribution = (link) => {
  window.location.href = link
}
const goToIncome = (link) => {
  window.location.href = link
}
const goToIntegration = (link) => {
  window.location.href = link
}
const goToMobile = (link) => {
  window.location.href = link
}
const goToDataWarehouses = (link) => {
  window.location.href = link
}
const goToDataAnalytics = (link) => {
  window.location.href = link
}
const goToServicesWeb = () => {
  // router.push({ name: 'ServiciosWeb3' }, { replace: true })
  window.location.href = '/tmd-pro-access/contratos-inteligentes/servicios-web3'
}
const isHome = ref(true)
console.log('currentUrl', currentUrl)
if (currentUrl === '/') {
  isPremium.value = false
  isHome.value = false
}

//COLLAPSE AUTOMATICO
onMounted(() => {
  const collapseTriggers = document.querySelectorAll(
    '[data-bs-toggle="collapse"]'
  )

  // Añadir click
  collapseTriggers.forEach((trigger) => {
    trigger.addEventListener('click', function () {
      const target = this.getAttribute('data-bs-target')
      // Cerrar todos los collapses excepto el que se va a abrir
      collapseTriggers.forEach((t) => {
        const collapseTarget = t.getAttribute('data-bs-target')
        if (collapseTarget !== target) {
          const collapseElement = document.querySelector(collapseTarget)
          if (collapseElement.classList.contains('show')) {
            new Collapse(collapseElement).hide()
          }
        }
      })
    })
  })
})
</script>
<template>
  <header class="header transparent">
    <div class="header-logo">
      <a class="a-href" href="/">
        <img :src="logotmd" alt="logo" class="img1" />
        <img :src="TMD" alt="logo" class="img2" />
        <!-- <h3>The Music Distribution</h3> -->
      </a>
    </div>
    <nav class="header-nav" v-show="!isTabletOrSmaller">
      <a href="/">HOME</a>
      <a href="/tmd-access">TMD ACCESS</a>
      <div class="d-flex flex-column">
        <a
          href="/tmd-pro-access/servicios-empresariales/gestion-catalogos"
          class="title-a"
          :style="isPremium ? 'padding-top: 7%' : 'padding-top: 0%'"
          >TMD PRO ACCESS</a
        >
        <div v-if="isPremium" class="d-flex flex-row gap-4">
          <div>
            <button
              class="a"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseMenu"
              aria-expanded="false"
              aria-controls="collapseMenu"
              style="font-weight: 400"
            >
              Servicios Empresariales
              <img
                :src="arrow_down"
                alt="arrow"
                style="height: 10px; width: 10px; margin-left: 3px"
              />
            </button>

            <div class="collapse" id="collapseMenu">
              <div class="card card-body collapse-container" style="z-index: 3">
                <div class="card card-body card-container-02">
                  <div
                    type="button"
                    @click="
                      goToCatalogos(
                        '/tmd-pro-access/servicios-empresariales/gestion-catalogos'
                      )
                    "
                    class="card card-body card-01"
                  >
                    <img
                      :src="catalogos"
                      alt="catalogos-image"
                      style="width: 30px; height: 30px"
                    />
                    <div>
                      <h6>Gestión de catálogos</h6>
                      <p>Gestione sus activos</p>
                    </div>
                  </div>
                  <div
                    type="button"
                    @click="
                      goToDistribution(
                        '/tmd-pro-access/servicios-empresariales/distribucion-entrega'
                      )
                    "
                    class="card card-body card-01"
                  >
                    <img
                      :src="distribution"
                      alt="distribution-image"
                      style="width: 30px; height: 30px"
                    />
                    <div>
                      <h6>Distribución y Entrega</h6>
                      <p>Monetizar en todas partes</p>
                    </div>
                  </div>
                  <div
                    type="button"
                    @click="
                      goToIncome(
                        '/tmd-pro-access/servicios-empresariales/informes-ingresos'
                      )
                    "
                    class="card card-body card-01"
                  >
                    <img
                      :src="ingresos"
                      alt="ingresos-image"
                      style="width: 30px; height: 30px"
                    />
                    <div>
                      <h6>Informes de ingresos</h6>
                      <p>Simplificar la elaboración de informes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              class="a"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseMenu2"
              aria-expanded="false"
              aria-controls="collapseMenu"
              style="font-weight: 400"
            >
              Servicios WEB3
              <img
                :src="arrow_down"
                alt="arrow"
                style="height: 10px; width: 10px; margin-left: 3px"
              />
            </button>

            <div class="collapse" id="collapseMenu2">
              <div class="card card-body collapse-container" style="z-index: 3">
                <div class="card card-body card-container-02">
                  <div
                    type="button"
                    @click="goToServicesWeb"
                    class="card card-body card-01"
                  >
                    <img
                      :src="contratos"
                      alt="contratos-image"
                      style="width: 30px; height: 30px"
                    />
                    <div>
                      <h6>Contratos inteligentes</h6>
                      <p>Acuerdos en cadena</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- COLLAPSE MENU BIGSCREEN -->
      <!-- <button
        class="a"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseMenu"
        aria-expanded="false"
        aria-controls="collapseMenu"
      >
        TMD PRO ACCESS
        <img
          :src="arrow_down"
          alt="arrow"
          style="height: 10px; width: 10px; margin-left: 3px"
        />
      </button>

      <div class="collapse" id="collapseMenu">
        <div class="card card-body collapse-container" style="z-index: 3">
          <div class="card card-body card-container-02">
            <h6>SERVICIOS EMPRESARIALES</h6>
            <div
              type="button"
              @click="
                goToCatalogos(
                  '/tmd-pro-access/servicios-empresariales/gestion-catalogos'
                )
              "
              class="card card-body card-01"
            >
              <img
                :src="catalogos"
                alt="catalogos-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>Gestión de catálogos</h6>
                <p>Gestione sus activos</p>
              </div>
            </div>
            <div
              type="button"
              @click="
                goToDistribution(
                  '/tmd-pro-access/servicios-empresariales/distribucion-entrega'
                )
              "
              class="card card-body card-01"
            >
              <img
                :src="distribution"
                alt="distribution-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>Distribución y Entrega</h6>
                <p>Monetizar en todas partes</p>
              </div>
            </div>
            <div
              type="button"
              @click="
                goToIncome(
                  '/tmd-pro-access/servicios-empresariales/informes-ingresos'
                )
              "
              class="card card-body card-01"
            >
              <img
                :src="ingresos"
                alt="ingresos-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>Informes de ingresos</h6>
                <p>Simplificar la elaboración de informes</p>
              </div>
            </div>
          </div> -->

      <!--div class="card card-body card-container-02">
            <h6>SERVICIOS DE DATOS</h6>
            <div
              type="button"
              @click="
                goToIntegration(
                  '/tmd-pro-access/servicios-datos/integracion-datos'
                )
              "
              class="card card-body card-01"
            >
              <img
                :src="integraciones"
                alt="integraciones-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>Integraciones</h6>
                <p>Integrar todas las fuentes de datos</p>
              </div>
            </div>
            <div
              type="button"
              @click="
                goToDataWarehouses(
                  '/tmd-pro-access/servicios-datos/almacenamiento-datos'
                )
              "
              class="card card-body card-01"
            >
              <img
                :src="data"
                alt="data-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>Almacenes de datos</h6>
                <p>Almacenes de datos totalmente gestionados</p>
              </div>
            </div>
            <div
              type="button"
              @click="
                goToDataAnalytics('/tmd-pro-access/servicios-datos/analitica')
              "
              class="card card-body card-01"
            >
              <img
                :src="group"
                alt="group-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>ANALÍTICA</h6>
                <p>Desbloquear conocimientos</p>
              </div>
            </div>
          </div-->

      <!-- <div class="card card-body card-container-02">
            <h6>SERVICIOS WEB3</h6>
            <div
              type="button"
              @click="goToServicesWeb"
              class="card card-body card-01"
            >
              <img
                :src="contratos"
                alt="contratos-image"
                style="width: 30px; height: 30px"
              />
              <div>
                <h6>Contratos inteligentes</h6>
                <p>Acuerdos en cadena</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div v-if="isHome" class="header-group">
        <div class="header-base-button">
          <button
            @click="goToPlattform(1)"
            type="button"
            class="header-button firstTransition"
          >
            SIGN UP
          </button>
        </div>
        <div class="header-base-button">
          <button
            @click="goToPlattform(2)"
            class="header-button firstTransition"
          >
            LOGIN
          </button>
        </div>
      </div>
    </nav>

    <!-- Mostrar menú hamburguesa cuando es tablet o menor -->
    <nav class="navbar navbar-expand-lg" v-show="isTabletOrSmaller">
      <button
        class="navbar-toggler custom-btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasDarkNavbar"
        aria-controls="offcanvasDarkNavbar"
        aria-label="Toggle navigation"
        @click="toggleMenu"
      >
        <span
          ><img
            :src="btn_lines"
            alt="button-lines"
            style="width: 40px; height: 40px"
        /></span>
      </button>
      <div
        class="offcanvas offcanvas-content"
        :class="[
          'offcanvas',
          'offcanvas-end',
          'burguer-menu',
          'w-100',
          isMenuVisible ? 'show' : '',
        ]"
        tabindex="-1"
        id="offcanvasDarkNavbar"
        aria-labelledby="offcanvasDarkNavbarLabel"
      >
        <div class="img-group">
          <img :src="logotmd" alt="logo" style="width: 48px; height: 48px" />
          <button @click="toggleMenu">
            <img
              :src="x_menu"
              alt="close-icon"
              style="width: 35px; height: 35px"
            />
          </button>
        </div>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="/">HOME</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/tmd-access">TMD ACCESS</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/tmd-pro-access/servicios-empresariales/gestion-catalogos"
              >TMD PRO ACCESS</a
            >
          </li>
        </ul>
        <!-- Collapse init -->
        <div class="dropdown-group" :class="['dropdown-group']">
          <!-- Collapse 1 -->
          <div class="button-container">
            <button
              class="btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse1"
              @click="toggleCollapse('collapse1')"
              :aria-expanded="activeCollapse === 'collapse1'"
              aria-controls="collapse1"
            >
              SERVICIOS EMPRESARIALES
            </button>
            <img :src="arrow_down" alt="arrow-down" />
          </div>
          <div
            :class="['collapse', { show: activeCollapse === 'collapse1' }]"
            id="collapse1"
          >
            <div
              type="button"
              @click="
                goToMobile(
                  '/tmd-pro-access/servicios-empresariales/gestion-catalogos'
                )
              "
              class="card card-body"
            >
              <img
                :src="catalogos"
                alt="catalogos-image"
                style="width: 40px; height: 40px"
              />
              <div>
                <h5>Gestión de catálogos</h5>
                <p>Gestione sus activos</p>
              </div>
            </div>
            <div
              type="button"
              @click="
                goToMobile(
                  '/tmd-pro-access/servicios-empresariales/distribucion-entrega'
                )
              "
              class="card card-body"
            >
              <img
                :src="distribution"
                alt="distribution-image"
                style="width: 40px; height: 40px"
              />
              <div>
                <h5>Distribución y Entrega</h5>
                <p>Monetizar en todas partes</p>
              </div>
            </div>
            <div
              type="button"
              @click="
                goToMobile(
                  '/tmd-pro-access/servicios-empresariales/informes-ingresos'
                )
              "
              class="card card-body"
            >
              <img
                :src="ingresos"
                alt="ingresos-image"
                style="width: 40px; height: 40px"
              />
              <div>
                <h5>Informes de ingresos</h5>
                <p>Simplificar la elaboración de informes</p>
              </div>
            </div>
          </div>
          <!--  Collapse 2 -->
          <!--div>
            <div class="button-container">
              <button
                class="btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-controls="collapse2"
                @click="toggleCollapse('collapse2')"
                :aria-expanded="activeCollapse === 'collapse2'"
              >
                SERVICIOS DE DATOS
              </button>
              <img :src="arrow_down" alt="arrow-down" />
            </div>
            <div
              :class="['collapse', { show: activeCollapse === 'collapse2' }]"
              id="collapse2"
            >
              <div
                type="button"
                @click="
                  goToMobile(
                    '/tmd-pro-access/servicios-datos/integracion-datos'
                  )
                "
                class="card card-body"
              >
                <img
                  :src="integraciones"
                  alt="integraciones-image"
                  style="width: 40px; height: 40px"
                />
                <div>
                  <h5>Integraciones</h5>
                  <p>Integrar todas las fuentes de datos</p>
                </div>
              </div>
              <div @click="goToDataWarehouses" class="card card-body">
                <img
                  :src="data"
                  alt="data-image"
                  style="width: 40px; height: 40px"
                />
                <div>
                  <h5>Almacenes de datos</h5>
                  <p>Almacenes de datos totalmente gestionados</p>
                </div>
              </div>
              <div class="card card-body" @click="goToDataAnalytics">
                <img
                  :src="group"
                  alt="group-image"
                  style="width: 40px; height: 40px"
                />
                <div>
                  <h5>ANALÍTICA</h5>
                  <p>Desbloquear conocimientos</p>
                </div>
              </div>
            </div>
          </div-->
          <!--  Collapse 3 -->
          <div>
            <div class="button-container">
              <button
                class="btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-controls="collapse3"
                @click="toggleCollapse('collapse3')"
                :aria-expanded="activeCollapse === 'collapse3'"
              >
                SERVICIOS WEB3
              </button>
              <img :src="arrow_down" alt="arrow-down" />
            </div>
            <div
              :class="['collapse', { show: activeCollapse === 'collapse3' }]"
              id="collapse3"
            >
              <div class="card card-body" @click="goToServicesWeb">
                <img
                  :src="contratos"
                  alt="contratos-image"
                  style="width: 40px; height: 40px"
                />
                <div>
                  <h5>Contratos inteligentes</h5>
                  <p>Acuerdos en cadena</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isHome" class="container-btns">
          <div class="header-base-button">
            <button
              type="button"
              @click="goToPlattform(1)"
              class="header-button firstTransition"
            >
              SIGN UP
            </button>
          </div>
          <div class="header-base-button">
            <button
              type="button"
              @click="goToPlattform(2)"
              class="header-button firstTransition"
            >
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<style lang="scss" scoped>
@media only screen and (min-width: 992px) {
  .navbar {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    display: block;
  }
  .header-logo {
    padding-left: 0px;
  }
}

.collapse {
  transition: height 0.4s ease-in-out;
}

.offcanvas {
  display: block;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  contain: content;
  height: 100%;
  overflow-y: auto;
}
.offcanvas-header,
.offcanvas-body {
  height: 100%;
}

.offcanvas.show {
  transform: translateX(0);
}
/* Estilos para el contenido colapsado */
#collapseMenu {
  position: absolute;
  top: 100%;
  left: 45%;
  width: 50%;
  z-index: 2;
}
#collapseMenu2 {
  position: absolute;
  top: 100%;
  left: 45%;
  width: 50%;
  z-index: 2;
}

.collapse-container {
  color: white;
  background: black;
  display: flex;
  flex-direction: row;
  gap: 7px;
  z-index: 2;
}
</style>
