<script setup>
import { gsap } from 'gsap'
import { onMounted, watch, ref, onUnmounted } from 'vue'
import linesBlue2 from '@/assets/images/parallax_imgs/linesBlue2.svg'
import linesBeige2 from '@/assets/images/parallax_imgs/linesBeige2.svg'
import linesYellowBlue from '@/assets/images/parallax_imgs/linesYellowBlue.svg'
import linesYellowBlue2 from '@/assets/images/parallax_imgs/linesYellowBlue2.svg'
import linesBlueRed from '@/assets/images/parallax_imgs/linesBlueRed.svg'
import frame_130 from '@/assets/images/servicios_empresariales/frame_130.png'
import frame_131 from '@/assets/images/servicios_empresariales/frame_131.png'
import distribution_w from '@/assets/images/servicios_empresariales/distribution_w.svg'
import clip_dollar_w from '@/assets/images/clip_dollar_w.svg'
import data_w from '@/assets/images/servicios_empresariales/data_w.svg'
import grafica2 from '@/assets/images/gifs/grafica2.gif'
import info_ingresos from '@/assets/images/servicios_empresariales/info_ingresos.png'
import suggested_match from '@/assets/images/servicios_empresariales/suggested_match.png'
import revenue_b_c from '@/assets/images/servicios_empresariales/revenue_b_c.png'
import AnimatedButton from '@/components/Home/AnimatedButton.vue'
import CardColor from '@/components/UserPremium/CardColor.vue'

/* PARRALAX */
let parallaxContainer = null
let layers = null

onMounted(() => {
  parallaxContainer = document.querySelector('.parallax-container')
  layers = document.querySelectorAll('.layer')

  gsap.set(layers, { x: 0, y: 0 }) // set initial position
  gsap.set(layers[2], { y: 1500 })
  gsap.set(layers[3], { y: 1500 })
  gsap.set(layers[4], { y: 3200 })

  parallaxContainer.addEventListener('scroll', () => {
    const scrollPosition = parallaxContainer.scrollTop
    const speed = 0.8

    if (scrollPosition > 980) {
      gsap.to(layers[0], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[0], {
        x: -scrollPosition * speed,
        y: scrollPosition * (speed * 1.3),
        ease: 'none',
        duration: 0.7,
      })
    }

    if (scrollPosition > 900) {
      gsap.to(layers[1], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[1], {
        y: scrollPosition * (speed * 0.6),
        ease: 'none',
        duration: 0.6,
      })
    }

    if (scrollPosition > 2900) {
      gsap.to(layers[2], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[2], {
        y: scrollPosition * (speed * 0.7),
        ease: 'none',
        duration: 0.8,
      })
    }

    if (scrollPosition > 2700) {
      gsap.to(layers[3], {
        x: 0,
        y: 0,
        ease: 'none',
        duration: 0.7,
      })
    } else {
      gsap.to(layers[3], {
        y: scrollPosition * (speed * 1.1),
        ease: 'none',
        duration: 0.8,
      })
    }

    gsap.to(layers[4], {
      x: -scrollPosition * (speed * 0.5),
      ease: 'none',
      duration: 0.8,
    })
  })
})

//DSPLAZAMIENTO AUTOMATICO
onMounted(() => {
  const sections = document.querySelectorAll('section')
  sections.forEach((section) => {
    section.addEventListener('click', () => {
      const nextSection = section.nextElementSibling
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' })
      }
    })
  })
})
//FONDO GRADIENTE
// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const elements = document.querySelectorAll('.bgTransition')

  // Definir los gradientes
  const gradient1 = `linear-gradient(114deg, rgba(242, 168, 37, 0.8) -1.23%, rgba(30, 137, 187, 0.8) 84.52%)`
  const gradient2 = `linear-gradient(52deg, rgba(242, 168, 37, 0.8) 11.9%, rgba(30, 137, 187, 0.8) 82.21%)`
  const gradient3 = `linear-gradient(288deg, rgba(242, 168, 37, 0.8) 4.9%, rgba(30, 137, 187, 0.8) 63.75%)`

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(elements, { background: gradient1, duration: 3, ease: 'none' })
    .to(elements, { background: gradient2, duration: 3, ease: 'none' })
    .to(elements, { background: gradient3, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

//Cambiar la imagen dependiendo la pantalla
// Estado reactivo para el ancho de pantalla
const anchoPantalla = ref(window.innerWidth)

const actualizarAnchoPantalla = () => {
  anchoPantalla.value = window.innerWidth
  console.log('Ancho de pantalla actualizado:', anchoPantalla.value)
}

onMounted(() => {
  window.addEventListener('resize', actualizarAnchoPantalla)
  console.log('Componente montado, ancho inicial:', anchoPantalla.value)
})

onUnmounted(() => {
  window.removeEventListener('resize', actualizarAnchoPantalla)
})

const imagen = ref(anchoPantalla.value <= 1024 ? frame_131 : frame_130)

watch(anchoPantalla, (nuevoValor) => {
  console.log('Nuevo valor de ancho de pantalla:', nuevoValor)
  if (nuevoValor <= 1024) {
    imagen.value = frame_131
  } else {
    imagen.value = frame_130
  }
  console.log('Imagen actual:', imagen.value)
})
// eslint-disable-next-line
const urlLogin = ref('https://panel.themusicdistribution.com/')
// eslint-disable-next-line
const urlSingUp = ref('https://panel.themusicdistribution.com/es/sign-up')
const currentUrl = window.location.pathname
if (currentUrl.indexOf('tmd-access') > -1) {
  urlLogin.value = 'https://distribucion.themusicdistribution.com/'
  urlSingUp.value =
    'https://distribucion.themusicdistribution.com/users/register'
}

const goToPlattform = (type) => {
  if (type === 2) {
    window.location.href = urlLogin.value
  } else {
    window.location.href = urlSingUp.value
  }
}
</script>
<template>
  <div>
    <div class="income-reports">
      <div class="parallax-container">
        <section id="section1" class="section bgTransition">
          <div class="layer-1">
            <img :src="linesBlue2" alt="Layer 1" class="layer-image1 layer" />
            <img :src="linesBeige2" alt="Layer 2" class="layer-image2 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>Simplificar la elaboración de informes</h1>
                <h4>
                  The Music Distribution facilita la recopilación, el
                  procesamiento y la sincronización de todos sus informes de
                  ventas digitales en todos los DSP.
                </h4>
                <div class="form-group">
                  <button
                    type="button"
                    @click="goToPlattform(1)"
                    class="submit-button"
                    style="text-wrap: nowrap"
                  >
                    OBTENLO AHORA
                  </button>
                </div>
              </div>
              <div class="second-container">
                <img
                  :src="grafica2"
                  alt="logo-gif"
                  style="width: 100%; height: 100%; object-fit: fill"
                />
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container">
              <div class="box">
                <div class="box-div-first">
                  <span class="text"> Automatice</span>
                  <span class="sub-text">Ingestión</span>
                </div>
                <div class="box-div-second">
                  <span class="text">Streamline</span>
                  <span class="sub-text">Procesa</span>
                </div>
                <div class="box-div-third">
                  <span class="text">Acelerar</span>
                  <span class="sub-text">Flujos de trabajo</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 2 -->
        <section id="section2" class="section bgBlack">
          <div class="layer-2">
            <img
              :src="linesYellowBlue"
              alt="Layer 3"
              class="layer-image3 layer"
            />
            <img
              :src="linesYellowBlue2"
              alt="Layer 4"
              class="layer-image4 layer"
            />
          </div>
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-02">
                  <p>Unificar</p>
                </div>
              </div>
              <h2>Consolide todos sus informes de ingresos</h2>
              <p>
                Aporte claridad a sus informes de ingresos y datos de streaming
                en un solo panel. Consolide sus informes de ingresos y uso en
                todos los canales.
              </p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="img-cont">
              <div class="container-img">
                <img :src="info_ingresos" alt="songs" class="image-songs" />
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 3 -->
        <section id="section3" class="section bgBlack">
          <div class="layer-3">
            <img :src="linesBlueRed" alt="Layer 5" class="layer-image5 layer" />
          </div>
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-02">
                  <p>Partido</p>
                </div>
              </div>
              <h2>Simplificar la correspondencia y la atribución</h2>
              <p>
                Dedique menos tiempo a descargar archivos CSV. Asegúrese de que
                todos los datos de ingresos se atribuyen correctamente a los
                derechos de autor y activos de catálogo correctos.
              </p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="img-cont">
              <div class="container-img">
                <img
                  :src="suggested_match"
                  alt="songs"
                  class="image-suggested"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- SECTION 4 -->
        <section id="section4" class="section overlay-section">
          <div class="order-fourth">
            <div class="container">
              <div>
                <div class="div-label-02">
                  <p>Analizar</p>
                </div>
              </div>
              <h2>Analice datos financieros con facilidad</h2>
              <p>
                Visualice sus datos financieros en cuadros de mando y profundice
                en las transacciones de ventas con precisión granular.
              </p>
              <AnimatedButton></AnimatedButton>
            </div>
            <!-- aqui -->
            <div class="d-flex justify-content-center w-100 overflow-hidden">
              <div class="img-cont-02">
                <div class="container-img">
                  <img :src="revenue_b_c" alt="songs" class="image-songs" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        id="section5"
        class="bgTransition overlay-section2 z-2"
        style="height: auto !important; overflow-x: hidden"
      >
        <div style="height: 100%; width: 100%">
          <div class="layer-1" style="width: 100%; height: 100%">
            <img :src="linesBlue2" alt="Layer 1" class="img-last-01 layer" />
            <img :src="linesBeige2" alt="Layer 2" class="img-last-02 layer" />
          </div>
          <div class="order">
            <div class="central-content">
              <div class="first-container">
                <h1>
                  Obtener The music <br />
                  distribution
                </h1>
                <h4>Para simplificar tus informes.</h4>
                <div class="form-group">
                  <button
                    type="button"
                    @click="goToPlattform(1)"
                    class="submit-button"
                  >
                    OBTENLO AHORA
                  </button>
                </div>
              </div>
            </div>
            <!-- *Se esconde en phone-->
            <div class="third-container-last" style="min-height: 150px">
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="distribution_w"
                  title="Distribución y entrega"
                  text="Monetizar en todas partes"
                  url="/tmd-pro-access/servicios-empresariales/distribucion-entrega"
                  background="linear-gradient(138deg, #7D1426 -37.97%, #D02140 108.31%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="clip_dollar_w"
                  url="/tmd-pro-access/servicios-empresariales/informes-ingresos"
                  title="Informes de ingresos"
                  text="Simplificar la elaboración"
                  background="linear-gradient(92deg, #DA9721 -0.12%, #F2A825 100.29%)"
                ></CardColor>
              </div>
              <div class="d-flex align-items-center">
                <CardColor
                  :imgSrc="data_w"
                  url="/tmd-pro-access/servicios-datos/almacenamiento-datos"
                  title="Almacenes de datos"
                  text="Almacenes de datos totalmente gestionados"
                  background=" linear-gradient(92deg, #2198D0 -0.12%, #1E89BB 100.29%)"
                ></CardColor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.overlay-section2 {
  position: relative;
}

.overlay-section {
  position: relative;
}

.overlay-section::before {
  content: '';
  position: absolute;
  bottom: -0px;
  left: 0;
  width: 100%;
  height: 293px; /* Ajusta la altura del resplandor */
  background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #bc9337 100%);
  pointer-events: none;
  opacity: 0.56;
  z-index: 3 !important; /* Asegura que el resplandor esté sobre el contenido */
}

.bgBlack {
  width: 100%;
  height: 100vh;
}
.bgTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    52deg,
    rgba(242, 168, 37, 0.8) 11.9%,
    rgba(30, 137, 187, 0.8) 82.21%
  );
}
.central-content {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 6rem;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .central-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    height: 100vh;
    width: 100%;
  }
}

.parallax-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
@media (max-width: 768px) {
  .parallax-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}

.layer {
  position: absolute;
  transform: translateZ(-1px);
}

.layer-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}
.layer-image1 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: -139px;
  bottom: -54px;
}

.layer-image2 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 140px;
}
.layer-image3 {
  width: 618px;
  height: 618px;
  left: -100px;
  bottom: -45%;
  opacity: 0.3;
}
.layer-image4 {
  width: 340px;
  height: 485px;
  right: -120px;
  bottom: 5%;
  opacity: 0.3;
}
.layer-image5 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: 12%;
  top: -120%;
  z-index: 2;
}

@media (max-width: 768px) {
  .layer-image1 {
    width: 518px;
    height: 518px;
    opacity: 0.2;
    right: -139px;
    bottom: -54px;
  }

  .layer-image2 {
    opacity: 0.3;
    width: 240px;
    height: 385px;
    right: -150px;
    bottom: 140px;
  }
  .layer-image3 {
    width: 508px;
    height: 508px;
    left: -100px;
    bottom: -45%;
    opacity: 0.3;
  }
  .layer-image4 {
    width: 240px;
    height: 385px;
    right: -120px;
    bottom: -100px;
    opacity: 0.4;
  }
  .layer-image5 {
    opacity: 0.3;
    width: 240px;
    height: 385px;
    right: -75%;
    top: -55%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .layer-image5 {
    right: -110%;
    top: 15%;
    opacity: 0.3;
  }
}
@media (min-width: 1600px) {
  .layer-image1 {
    opacity: 0.2;
    right: 0%;
    bottom: -10%;
  }
  .layer-image4 {
    right: -120px;
    bottom: -20%;
    opacity: 0.4;
  }
  .layer-image5 {
    opacity: 0.3;
    right: 15%;
    top: -50%;
    z-index: 1;
  }
}
@media (max-width: 480px) {
  /* Estilos para dispositivos móviles pequeños */
  .layer-image1 {
    width: 300px;
    height: 300px;
    opacity: 0.2;
    right: -50px;
    bottom: -20px;
  }
  .layer-image2 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -70px;
    bottom: 100px;
  }
  .layer-image3 {
    width: 300px;
    height: 300px;
    left: -50px;
    bottom: -20%;
    opacity: 0.5;
  }
  .layer-image4 {
    width: 180px;
    height: 280px;
    right: -60px;
    bottom: -50px;
    opacity: 0.4;
  }
  .layer-image5 {
    opacity: 0.3;
    width: 180px;
    height: 280px;
    right: -260%;
    top: -200px;
  }
}
.img-last-01 {
  width: 618px;
  height: 618px;
  opacity: 0.2;
  right: 5%;
  bottom: 20%;
  overflow: hidden;
}
.img-last-02 {
  opacity: 0.3;
  width: 340px;
  height: 485px;
  right: -190px;
  bottom: 30%;
  overflow: hidden;
}
</style>
