import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '@/views/LandingPage'
import TheHome from '@/views/TheHome'
import CatalogManagement from '@/views/business-services/CatalogManagement.vue'
import DistributionAndDelivery from '@/views/business-services/DistributionAndDelivery.vue'
import BasicUser from '@/views/basic-user/BasicUser.vue'
import IncomeReports from '@/views/business-services/IncomeReports.vue'
import IntegrateData from '@/views/data-services/IntegrateData.vue'
import DataWarehouses from '@/views/data-services/DataWarehouses.vue'
import DataAnalytics from '@/views/data-services/DataAnalytics.vue'
import WebServices from '@/views/web3-services/WebServices.vue'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
    children: [
      {
        path: '/',
        name: 'Home',
        component: TheHome,
      },
      {
        path: 'tmd-access',
        name: 'Basico',
        component: BasicUser,
      },
      {
        path: 'tmd-pro-access/servicios-empresariales/',
        name: 'ServiciosEmpresariales',
        children: [
          {
            path: 'gestion-catalogos',
            name: 'GestionCatalogos',
            component: CatalogManagement,
          },
          {
            path: 'distribucion-entrega',
            name: 'Distribucion-Entrega',
            component: DistributionAndDelivery,
          },
          {
            path: 'informes-ingresos',
            name: 'Informes-Ingresos',
            component: IncomeReports,
          },
        ],
      },
      {
        path: 'tmd-pro-access/servicios-datos/',
        name: 'ServiciosDatos',
        children: [
          {
            path: 'integracion-datos',
            name: 'IntegracionDatos',
            component: IntegrateData,
          },
          {
            path: 'almacenamiento-datos',
            name: 'AlmacenamientoDatos',
            component: DataWarehouses,
          },
          {
            path: 'analitica',
            name: 'Analitica',
            component: DataAnalytics,
          },
        ],
      },
      {
        path: 'tmd-pro-access/contratos-inteligentes/',
        name: 'ContratosInteligentes',
        children: [
          {
            path: 'servicios-web3',
            name: 'ServiciosWeb3',
            component: WebServices,
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
