<script setup>
import { gsap } from 'gsap'
import { ref } from 'vue'
import AnimatedButton from '@/components/Home/AnimatedButton.vue'
import CardColor from '@/components/UserPremium/CardColor.vue'
import img_songs from '@/assets/images/img_songs.png'
import img_28 from '@/assets/images/img_28.png'
import gif01 from '@/assets/images/gifs/gif01.gif'
import distribution_w from '@/assets/images/servicios_empresariales/distribution_w.svg'
import clip_dollar_w from '@/assets/images/clip_dollar_w.svg'
import data_w from '@/assets/images/servicios_empresariales/data_w.svg'
import reproductor from '@/assets/images/reproductor.png'
import lines01 from '@/assets/images/parallax_imgs/lines01.svg'
import lines02 from '@/assets/images/parallax_imgs/lines02.svg'
import lines10 from '@/assets/images/parallax_imgs/lines10.svg'
import lines11 from '@/assets/images/parallax_imgs/lines11.svg'
import lines12 from '@/assets/images/parallax_imgs/lines12.svg'
import lines13 from '@/assets/images/parallax_imgs/lines13.svg'
import lines14 from '@/assets/images/parallax_imgs/lines14.svg'
import lines15 from '@/assets/images/parallax_imgs/lines15.svg'

// Método para animar el fondo con gradientes first section
const animateBackground = () => {
  // Seleccionar el elemento
  const element = document.querySelectorAll('.bgTransition')

  // Definir los gradientes
  const gradient1 = 'linear-gradient(127deg, #205771 18.11%, #000 88.57%)'
  const gradient2 = 'linear-gradient(72deg, #205771 0%, #000 81.79%)'
  const gradient3 = 'linear-gradient(293deg, #205771 3.42%, #000 89.99%)'

  // Crear la animación con GSAP
  gsap
    .timeline({ repeat: -1, yoyo: true })
    .to(element, { background: gradient2, duration: 3, ease: 'none' })
    .to(element, { background: gradient3, duration: 3, ease: 'none' })
    .to(element, { background: gradient1, duration: 3, ease: 'none' })
}

// Método para llamar a la animación cuando el componente se monta
document.addEventListener('DOMContentLoaded', () => {
  animateBackground()
})

//PARALLAX
let parallaxElements = document.querySelectorAll('.parallax')

window.addEventListener('scroll', function () {
  parallaxElements.forEach(function (element) {
    let scrollPosition = window.pageYOffset
    let elementTop = element.getBoundingClientRect().top
    let elementHeight = element.offsetHeight
    let scrollPercent = (scrollPosition - elementTop) / (elementHeight * 0.6)

    element.style.transform =
      'translate3d(0px, ' + scrollPercent * 100 + 'px, 0px)'
  })
})

// Método para animar el desplazamiento suave
const scrollToNextSection = (currentSectionId) => {
  const currentSectionIndex = parseInt(currentSectionId.replace('section', ''))
  const nextSectionIndex = currentSectionIndex + 1
  const nextSectionId = `section${nextSectionIndex}`

  const nextSection = document.getElementById(nextSectionId)

  if (nextSection) {
    const yPos = nextSection.offsetTop
    window.scrollTo({ top: yPos, behavior: 'smooth' })
    // Imprimir los valores de las referencias
    console.log('currentSectionId:', currentSectionId)
    console.log('nextSectionId:', nextSectionId)

    // Actualizar el parallax al hacer scroll
    parallaxElements.forEach(function (element) {
      let scrollPosition = window.pageYOffset
      let elementTop = element.getBoundingClientRect().top
      let elementHeight = element.offsetHeight
      let scrollPercent = (scrollPosition - elementTop) / (elementHeight * 1)

      element.style.transform =
        'translate3d(0px, ' + scrollPercent * 100 + 'px, 0px)'
    })
  }
}

window.onload = function () {
  parallaxElements = document.querySelectorAll('.parallax')
  console.log('PARALAX E:', parallaxElements)
}

// eslint-disable-next-line
const urlLogin = ref('https://panel.themusicdistribution.com/')
// eslint-disable-next-line
const urlSingUp = ref('https://panel.themusicdistribution.com/es/sign-up')
const currentUrl = window.location.pathname
if (currentUrl.indexOf('tmd-access') > -1) {
  urlLogin.value = 'https://distribucion.themusicdistribution.com/'
  urlSingUp.value =
    'https://distribucion.themusicdistribution.com/users/register'
}

const goToPlattform = (type) => {
  if (type === 2) {
    window.location.href = urlLogin.value
  } else {
    window.location.href = urlSingUp.value
  }
}
</script>
<template>
  <div class="catalog-management">
    <section
      id="section1"
      class="bgTransition parallax-section"
      @click="scrollToNextSection('section1')"
    >
      <div class="background-container">
        <img
          :src="lines01"
          alt="lines"
          class="parallax-image image1 parallax"
        />
        <img
          :src="lines02"
          alt="lines"
          class="parallax-image image2 parallax"
        />
      </div>
      <div class="order">
        <div class="central-content">
          <div class="first-container">
            <h1>
              Gestione sus <br />
              Activos
            </h1>
            <h4>
              Gestione fácilmente todos sus activos digitales, derechos de autor
              y metadatos en un solo lugar.
            </h4>
            <div class="form-group">
              <button
                type="button"
                @click="goToPlattform(1)"
                class="submit-button"
              >
                OBTENLO AHORA
              </button>
            </div>
          </div>
          <div class="second-container">
            <img
              :src="gif01"
              alt="vinilo-gif"
              style="width: 100%; height: 100%; object-fit: fill"
            />
          </div>
        </div>
        <!-- *Se esconde en phone-->
        <div class="third-container">
          <div class="box">
            <div class="box-div-first">
              <span class="text"> Simplifique </span>
              <span class="sub-text">Gestión de activos</span>
            </div>
            <div class="box-div-second">
              <span class="text">Unifique</span>
              <span class="sub-text">Metadatos</span>
            </div>
            <div class="box-div-third">
              <span class="text"> Optimice</span>
              <span class="sub-text">En todas partes</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- *Second section -->
    <section
      id="section2"
      class="bgBlack parallax-section"
      @click="scrollToNextSection('section2')"
    >
      <div class="background-container">
        <img
          :src="lines10"
          alt="lines"
          class="parallax-image image3 parallax"
        />
        <img
          :src="lines11"
          alt="lines"
          class="parallax-image image4 parallax"
        />
      </div>
      <div class="order-third">
        <div class="container">
          <h2>Automatiza las divisiones con colaboradores</h2>
          <p>
            Procesa fácilmente las divisiones a nivel pista o lanzamiento para
            creadores, colaboradores o contribuyentes. Simplifica los calculos
            para productores, remezcladores, artistas secundarios, interpretes o
            coautores.
          </p>

          <AnimatedButton></AnimatedButton>
        </div>
        <div class="img-cont">
          <div class="resizer">
            <img
              :src="reproductor"
              alt="reproductor"
              style="width: 100%; height: 100%; object-fit: fill"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- *Third section -->
    <section
      id="section3"
      class="bgBlack parallax-section"
      @click="scrollToNextSection('section3')"
    >
      <div class="background-container">
        <img
          :src="lines12"
          alt="lines"
          class="parallax-image image5 parallax"
        />
        <img
          :src="lines13"
          alt="lines"
          class="parallax-image image6 parallax"
        />
      </div>
      <div class="order-third">
        <div class="container">
          <div>
            <div class="div-label-01">
              <p>Unifique</p>
            </div>
          </div>
          <h2>Gestione todos sus metadatos y activos digitales</h2>
          <p>
            Gestione con precisión y sin problemas los derechos de autor de
            grabaciones y publicaciones en un único lugar. Asigne grabaciones de
            sonido a composiciones, identifique compositores, editores y
            sociedades de derechos asociadas a obras musicales en todas sus
            grabaciones.
          </p>
          <AnimatedButton></AnimatedButton>
        </div>
        <!-- aqui -->
        <div class="img-cont">
          <div class="container-img">
            <img :src="img_songs" alt="songs" class="image-songs" />
          </div>
        </div>
      </div>
    </section>
    <!-- *Fourth section -->
    <section id="section4" class="bgBlack parallax-section">
      <div class="background-container">
        <img
          :src="lines15"
          alt="lines"
          class="parallax-image image7 parallax"
        />
        <img
          :src="lines14"
          alt="lines"
          class="parallax-image image8 parallax"
        />
      </div>
      <div class="order-third">
        <div class="container">
          <div>
            <div class="div-label-02">
              <p>Optimice</p>
            </div>
          </div>
          <h2>Entregar metadatos perfectos</h2>
          <p>
            Señale fácilmente los errores de correspondencia de catálogos e
            inspeccione los problemas de metadatos con sofisticadas herramientas
            de gestión de errores. Normalice sus metadatos de acuerdo con las
            mejores prácticas del mercado digital.
          </p>
          <AnimatedButton></AnimatedButton>
        </div>
        <!-- aqui -->
        <div class="d-flex justify-content-center w-100">
          <div class="img-cont-02">
            <div class="container-img">
              <img :src="img_28" alt="songs" class="image-songs" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- *Fifth section -->
    <section id="section5" class="bgTransition overlay-section z-2">
      <div
        style="position: relative; height: 100%; overflow: hidden; width: 100%"
      >
        <div class="background-container">
          <img :src="lines01" alt="lines" class="parallax-image image9" />
          <img :src="lines02" alt="lines" class="parallax-image image10" />
        </div>
        <div class="order" style="min-height: auto">
          <div class="central-content">
            <div class="first-container">
              <h1>Obtener The music <br />distribution</h1>
              <h4>y gestione su catálogo hoy mismo.</h4>
              <div class="form-group">
                <button
                  type="button"
                  @click="goToPlattform(1)"
                  class="submit-button"
                >
                  OBTENLO AHORA
                </button>
              </div>
            </div>
          </div>
          <!-- *Se esconde en phone-->
          <div class="third-container-last" style="min-height: auto">
            <CardColor
              :imgSrc="distribution_w"
              title="Distribución y entrega"
              text="Monetizar en todas partes"
              url="/tmd-pro-access/servicios-empresariales/distribucion-entrega"
              background="linear-gradient(138deg, #7D1426 -37.97%, #D02140 108.31%)"
            ></CardColor>
            <CardColor
              :imgSrc="clip_dollar_w"
              url="/tmd-pro-access/servicios-empresariales/informes-ingresos"
              title="Informes de ingresos"
              text="Simplificar la elaboración"
              background="linear-gradient(92deg, #DA9721 -0.12%, #F2A825 100.29%)"
            ></CardColor>
            <CardColor
              :imgSrc="data_w"
              title="Almacenes de datos"
              url="/tmd-pro-access/servicios-datos/almacenamiento-datos"
              text="Almacenes de datos totalmente gestionados"
              background=" linear-gradient(92deg, #2198D0 -0.12%, #1E89BB 100.29%)"
            ></CardColor>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
.overlay-section {
  position: relative;
}

.overlay-section::before {
  content: '';
  position: absolute;
  top: -292px;
  left: 0;
  width: 100%;
  height: 293px;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #205872 100%);
  pointer-events: none;
  opacity: 0.56;
  z-index: 1; /* Asegura que el resplandor esté sobre el contenido */
}

.overlay-section.sticky {
  box-shadow: 0 -20px 30px rgba(32, 88, 114, 0.5); /* Ajusta la sombra si es necesario */
}
.container-mask {
  position: absolute;
  right: -23%;
  z-index: 2;
}
@media (max-width: 1024px) {
  .container-mask {
    position: absolute;
    bottom: -20%;
    right: 0%;
    z-index: -1;
    transform: scale(0.7);
  }
}
@media (max-width: 767px) {
  .container-mask {
    position: absolute;
    bottom: -25%;
    right: 0%;
    z-index: -1;
    transform: scale(0.7);
  }
}

.bgSecondTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    127deg,
    rgba(72, 2, 19, 0.9) 18.11%,
    rgba(0, 0, 0, 0.9) 88.57%
  );
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; // Para que el pseudo-elemento esté detrás del contenido
  }
}
.bgTransition {
  width: 100%;
  height: 100vh;
  background: linear-gradient(127deg, rgb(32, 87, 113) 18.11%, #000 88.57%);
}
.central-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  padding: 0 100px;
}
.bgBlack {
  width: 100%;
  height: 100vh;
}
//PARALLAX STYLES
.parallax-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.parallax-image {
  position: absolute;
  will-change: transform;
  overflow: hidden;
}

.image1 {
  width: 600px;
  height: 590px;
  opacity: 0.2;
  top: 9%;
  right: 3%;
}
.image2 {
  opacity: 0.3;
  width: 470px;
  height: 470px;
  top: 18%;
  right: -10%;
}
.image3 {
  width: 600px;
  height: 590px;
  bottom: 10%;
  left: 0%;
  opacity: 0.5;
}
.image4 {
  width: 370px;
  height: 370px;
  bottom: 15%;
  right: 0%;
  opacity: 0.5;
}
.image5 {
  opacity: 0.5;
  width: 500px;
  height: 490px;
  bottom: 50%;
  left: 0%;
}
.image6 {
  opacity: 0.5;
  width: 270px;
  height: 270px;
  bottom: 70%;
  right: 0%;
}
.image7 {
  opacity: 0.5;
  width: 490px;
  height: 490px;
  bottom: 75%;
  right: -5%;
}
.image8 {
  opacity: 0.5;
  width: 440px;
  height: 440px;
  top: -115%;
  left: 0%;
}
.image9 {
  overflow: hidden;
  opacity: 0.3;
  width: 550px;
  height: 550px;
  bottom: -10%;
  right: -3%;
}
.image10 {
  overflow: hidden;
  opacity: 0.3;
  width: 460px;
  height: 460px;
  bottom: 5%;
  right: -11%;
}
@media (max-width: 768px) {
  .central-content {
    padding-right: 20px;
    padding-left: 20px;
  }
  .image1 {
    width: 400px;
    height: 400px;
    opacity: 0.2;
    top: 30%;
    right: -5%;
  }
  .image2 {
    width: 350px;
    height: 350px;
    top: 20%;
    right: -10%;
  }
  .image3 {
    width: 350px;
    height: 360px;
    opacity: 0.5;
    bottom: 10%;
    left: 0%;
  }
  .image4 {
    opacity: 0.5;
    width: 250px;
    height: 250px;
    bottom: 16%;
    right: 0%;
  }
  .image5 {
    opacity: 0.5;
    width: 350px;
    height: 360px;
    top: -3%;
    left: 0%;
  }
  .image6 {
    opacity: 0.5;
    width: 230px;
    height: 230px;
    top: -10%;
    right: 0%;
  }
  .image7 {
    opacity: 0.5;
    width: 350px;
    height: 360px;
    bottom: 110%;
    right: -10%;
  }
  .image8 {
    opacity: 0.5;
    width: 300px;
    height: 300px;
    top: -180%;
    left: 0%;
  }
  .image9 {
    overflow: hidden;
    opacity: 0.3;
    width: 400px;
    height: 400px;
    bottom: 0%;
    right: -10%;
  }
  .image10 {
    overflow: hidden;
    opacity: 0.3;
    width: 350px;
    height: 350px;
    bottom: 40%;
    right: -25%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .central-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  .image1 {
    width: 500px;
    height: 490px;
    opacity: 0.2;
    top: 30%;
    right: -5%;
  }
  .image2 {
    width: 460px;
    height: 460px;
    top: 20%;
  }
  .image3 {
    width: 500px;
    height: 490px;
    opacity: 0.5;
    top: 40%;
    left: -5%;
  }
  .image4 {
    opacity: 0.5;
    width: 310px;
    height: 310px;
    top: 55%;
    right: -5%;
  }
  .image5 {
    opacity: 0.5;
    width: 400px;
    height: 390px;
    top: -5%;
    left: 0%;
  }
  .image6 {
    opacity: 0.5;
    width: 250px;
    height: 250px;
    top: -5%;
    right: 0%;
  }
  .image7 {
    opacity: 0.5;
    width: 430px;
    height: 430px;
    bottom: 95%;
    right: -10%;
  }
  .image8 {
    opacity: 0.5;
    width: 400px;
    height: 390px;
    top: -130%;
    left: 0%;
  }
}
@media (min-width: 1600px) {
  .central-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding-right: 150px;
    padding-left: 180px;
  }
  .image1 {
    width: 650px;
    height: 650px;
    top: 9%;
    right: 7%;
  }
  .image2 {
    width: 570px;
    height: 570px;
  }
  .image3 {
    opacity: 0.5;
    width: 650px;
    height: 650px;
    bottom: 0%;
    right: 0%;
  }
  .image4 {
    opacity: 0.5;
    width: 460px;
    height: 460px;
    bottom: 0%;
  }
  .image5 {
    opacity: 0.5;
    width: 550px;
    height: 550px;
    bottom: 45%;
    left: 0%;
  }
  .image6 {
    opacity: 0.5;
    width: 360px;
    height: 360px;
    bottom: 60%;
    right: 0%;
  }
  .image7 {
    opacity: 0.5;
    width: 570px;
    height: 570px;
    bottom: 40%;
    right: -5%;
  }
  .image8 {
    opacity: 0.5;
    width: 470px;
    height: 470px;
    top: -100%;
    left: 0%;
  }
}
.section-5 {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .sticky-background {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .background-image1 {
      position: absolute;
      width: 140px;
      height: 420px;
      bottom: 20%;
      right: 0%;
      opacity: 0.2;
    }
    .background-image2 {
      position: absolute;
      width: 530px;
      height: 490px;
      bottom: 0%;
      left: 0%;
      opacity: 0.2;
    }
    @media (max-width: 1024px) {
      .background-image1 {
        position: absolute;
        width: 100px;
        height: 310px;
        bottom: 10%;
        right: 0%;
        opacity: 0.2;
      }
      .background-image2 {
        position: absolute;
        width: 390px;
        height: 350px;
        bottom: 0%;
        left: -7%;
        opacity: 0.2;
      }
    }
    @media (max-width: 767px) {
      .background-image1 {
        position: absolute;
        width: 70px;
        height: 250px;
        bottom: 10%;
        right: -5%;
        opacity: 0.2;
      }
      .background-image2 {
        position: absolute;
        width: 230px;
        height: 190px;
        bottom: 0%;
        left: 0%;
        opacity: 0.2;
      }
    }
  }
}
</style>
