<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  imgSrc: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
})
</script>
<template>
  <div class="card">
    <div class="card-img">
      <img :src="props.imgSrc" alt="image" class="card-img-img" />
    </div>
    <div>
      <p>{{ props.text }}</p>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
