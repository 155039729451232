<script setup>
import CardOfGrid from '@/components/Home/CardOfGrid.vue'
import clip_dollar from '@/assets/images/clip_dollar.svg'
import bar_code_scan from '@/assets/images/bar_code_scan.svg'
import ringtone from '@/assets/images/ringtone.svg'
import lyrics from '@/assets/images/lyrics.svg'
import distribution from '@/assets/images/distribution.svg'
import contratos from '@/assets/images/contratos.svg'
import loyalties from '@/assets/images/loyalties.svg'
import audio_lines from '@/assets/images/audio_lines.svg'
import territory from '@/assets/images/territory.svg'

const cards = [
  {
    imgSrc: clip_dollar,
    text: 'Informes de ingresos',
  },
  {
    imgSrc: lyrics,
    text: 'Agregación de letras',
  },
  {
    imgSrc: loyalties,
    text: 'Automate Royalties',
  },
  {
    imgSrc: bar_code_scan,
    text: 'Códigos UPC e ISRC gratis',
  },
  {
    imgSrc: contratos,
    text: 'Gestión de derechos',
  },
  {
    imgSrc: audio_lines,
    text: 'Huella digital de audio',
  },
  {
    imgSrc: ringtone,
    text: 'Distribución de rington',
  },
  {
    imgSrc: distribution,
    text: 'Distribución ilimitada',
  },
  {
    imgSrc: territory,
    text: 'Exclusión de territorio',
  },
]
</script>
<template>
  <div class="container">
    <h2>Entrega digital</h2>
    <div class="grid-container">
      <CardOfGrid
        v-for="(card, index) in cards"
        :key="index"
        :imgSrc="card.imgSrc"
        :text="card.text"
      ></CardOfGrid>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px; /* Espacio entre las cards */
}

@media (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .grid-container {
    max-height: 550px;
    overflow-y: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-container::-webkit-scrollbar {
    width: 0; /* Ocultar la barra de desplazamiento en WebKit (Chrome, Safari) */
  }
}
@media (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .grid-container {
    max-height: auto;
    overflow-y: auto;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columnas para pantallas medianas */
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1, 1fr; /* 1 columna para pantallas pequeñas */
  }
}
h2 {
  color: $white;
  text-align: center;
  font-family: $font-family-DM;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media (max-width: 768px) {
  h2 {
    color: $white;
    text-align: center;
    font-family: $font-family-DM;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
  }
}
</style>
