<script setup>
import { reactive, ref } from 'vue'
import { sendEmailPub } from '@/services/mail'

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  termsAccepted: false,
})
const showError = ref(false)
const submitted = ref(false)
const showMessage = ref(true)
const sendEmail = () => {
  submitted.value = true
  if (!form.termsAccepted) {
    showError.value = true
    return
  } else {
    showError.value = false
  }
  console.log('Nombre:', form.firstName)
  console.log('Correo:', form.email)
  console.log('Mensaje:', form.message)

  let data = new FormData()
  data.append('email', form.email)
  data.append('comentarios', form.message)
  data.append('nombre', form.firstName)
  data.append('apellido', form.lastName)
  showMessage.value = false
  try {
    sendEmailPub(data).then((response) => {
    showMessage.value = false
  })
  } catch (error) {
    console.log(error)
  }
  
}

</script>
<template>
  <div v-if="showMessage" class="form-container">
    <form action="" @submit.prevent="sendEmail">
      <div class="form-group">
        <label for="nombre">Nombre</label>
        <input
          id="nombre"
          placeholder="Ingresa tu nombre"
          v-model="form.firstName"
        />
      </div>
      <div class="form-group">
        <label for="apellidos">Apellidos</label>
        <input
          id="apellidos"
          placeholder="Ingresa tus apellidos"
          v-model="form.lastName"
        />
      </div>
      <div class="form-group">
        <label for="correo">Correo</label>
        <input
          id="correo"
          placeholder="correo@tucorreo.com"
          v-model="form.email"
        />
      </div>
      <div class="form-group">
        <label for="comentarios">Comentarios</label>
        <textarea
          id="comentarios"
          placeholder="Escribe comentarios o dudas adicionales"
          v-model="form.message"
        ></textarea>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          v-model="form.termsAccepted"
        />
        <label class="form-check-label" for="flexCheckDefault">
          Al continuar, aceptas las Política y términos de uso
        </label>
      </div>
      <!-- Mensaje de error -->
      <div v-if="showError" class="error-message">
        Debes aceptar los términos y condiciones para enviar el correo.
      </div>
      <div class="form-group">
        <button
          class="submit-button"
          :disabled="!form.termsAccepted && submitted"
          aria-label="Enviar solicitud de contacto"
          @click.prevent="sendEmail"
        >
          ENVIAR SOLICITUD
        </button>
      </div>
    </form>
  </div>
  <div v-else class="form-container">
    <div class="text">
      <h4>
        Tu solicitud se ha enviado.
      </h4>
    </div>
  </div>
</template>
<style scoped lang="scss">
.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: smaller;
  font-weight: 400;
}
</style>
